import React, { useEffect } from "react";
import { AppState } from "../../Store";
import { connect } from "react-redux";
import {createBulkOrder, fetchOrders, importOrdersFromTelema} from "../../Store/Telema/actions";
import TelemaOrdersTable from "../../Components/TelemaOrdersTable/TelemaOrdersTable";
import { TelemaOrder } from "../../Models/TelemaOrder";
import { TelemaBulkOrderDTO } from "../../Models/TelemaBulkOrder";

interface OrdersProps {
  fetchOrders: () => void;
  createBulkOrder: (b: TelemaBulkOrderDTO) => void;
  orders: TelemaOrder[];
  importOrdersFromTelema: () => void
}
function TelemaOrdersView(props: OrdersProps) {
  useEffect(() => {
    props.fetchOrders();
  }, []);

  return (
    <>
      <TelemaOrdersTable
        orders={props.orders}
        createBulkOrder={props.createBulkOrder}
        importOrdersFromTelema={props.importOrdersFromTelema}
      />
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  orders: state.telema.orders.sort(
    // @ts-ignore
    (a, b) => new Date(b.date) - new Date(a.date)
  ),
});

const mapDispatchToProps = {
  fetchOrders,
  createBulkOrder,
  importOrdersFromTelema
};
export default connect(mapStateToProps, mapDispatchToProps)(TelemaOrdersView);
