import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./types";
import apiClient from "../api";
import mappers from "./mappers";
import { TelemaBulkOrderActionType } from "../../Models/TelemaBulkOrder";
import ActionType from "../../Models/ActionType";
import { toXML } from "./utils";
import { fetchBulkOrders, fetchOrders, fetchRecAdvs } from "./actions";
import ApiClient from "../../Utils/ApiClient";

const localAPI = new ApiClient("http://192.168.1.210:1338/api/");

function* handleFetchOrders() {
  try {
    const { data } = yield call(apiClient.get, "telema-orders?_limit=-1");
    const orders = data.map((d: any) => mappers.mapToOrder(d));
    yield put({ type: types.FETCH_ORDERS_SUCCESS, payload: orders });
  } catch (e: any) {
    yield put({ type: types.FETCH_ORDERS_FAILURE });
    console.log(e);
  }
}

function* handleFetchBulkOrders() {
  try {
    const { data } = yield call(apiClient.get, "telema-bulk-orders/");
    const orders = data.map((d: any) => mappers.mapToBulkOrder(d));
    yield put({ type: types.FETCH_BULK_ORDERS_SUCCESS, payload: orders });
  } catch (e: any) {
    yield put({ type: types.FETCH_BULK_ORDERS_FAILURE });
    console.log(e);
  }
}

function* handleFetchRecAdvs() {
  try {
    const { data } = yield call(
      apiClient.get,
      "telema-receiving-advices/?_limit=100&_sort=id:DESC"
    );
    const recAdvs = data.map(mappers.mapToRecAdv);
    yield put({ type: types.FETCH_REC_ADVS_SUCCESS, payload: recAdvs });
  } catch (e: any) {
    yield put({ type: types.FETCH_REC_ADVS_FAILURE });
    console.log(e);
  }
}

function* handleCreateBulkOrder(action: TelemaBulkOrderActionType) {
  try {
    const body = mappers.mapBulkOrderDTO(action.bulkOrder);
    const { data } = yield call(apiClient.post, "telema-bulk-orders/", body);
    yield put(fetchOrders());
    yield put({ type: types.CREATE_BULK_ORDER_SUCCESS });
  } catch (e: any) {
    yield put({ type: types.CREATE_BULK_ORDER_FAILURE });
    console.log(e);
  }
}

function* handleDownloadBulkOrderXML(action: ActionType) {
  try {
    const { data } = yield call(
      apiClient.get,
      `telema-bulk-orders/${action.id}`
    );
    const bulkOrder = mappers.mapToSingleBulkOrder(data);
    //console.log(bulkOrder);
    toXML(bulkOrder);
    //yield put({ type: types.CREATE_BULK_ORDER_SUCCESS });
  } catch (e: any) {
    //yield put({ type: types.CREATE_BULK_ORDER_FAILURE });
    console.log(e);
  }
}

function* importDeliveryNotes() {
  try {
    const { data } = yield call(localAPI.get, `telema/delivery-notes`);
    yield put(fetchBulkOrders());
  } catch (e: any) {
    //yield put({ type: types.CREATE_BULK_ORDER_FAILURE });
    console.log(e);
  }
}

function* exportBulkOrderToTelema(action: ActionType) {
  try {
    const { data } = yield call(
      apiClient.get,
      `telema-bulk-orders/${action.id}`
    );

    console.log(mappers.mapToSingleBulkOrder(data));

    const bulkOrder = mappers.mapToSingleBulkOrder(data);

    const body = {
      delivery_note_number: bulkOrder.deliveryNote?.documentNumber,
      receiver_id: "9926", // Balbiino id in select
      order_numbers: bulkOrder.orders.map((o) => o.orderNumber),
      products: bulkOrder.deliveryNote?.products.map((p) => ({
        gtin: p.sellerGTIN,
        name: p.productName,
        quantity: p.quantity,
        unit: p.unit,
        origin: p.origin,
        batches: p.batches?.map((b) => ({
          lot: b.lot,
          best_before: b.bestBefore,
          quantity: b.quantity,
          unit: p.unit,
        })),
      })),
    };

    yield call(localAPI.post, "telema/create-delivery-note", body, {
      timeout: 30 * 1000,
    });

    //yield put(fetchBulkOrders())
  } catch (e: any) {
    //yield put({ type: types.CREATE_BULK_ORDER_FAILURE });
    console.log(e);
  }
}

function* importOrdersFromTelema() {
  try {
    const { data } = yield call(localAPI.get, "telema/orders");
    yield put(fetchOrders());
  } catch (e: any) {
    //yield put({ type: types.CREATE_BULK_ORDER_FAILURE });
    console.log(e);
  }
}

function* handleImportRecAdvs() {
  try {
    const { data } = yield call(localAPI.get, "telema/recadv");
    yield put(fetchRecAdvs());
  } catch (e: any) {
    //yield put({ type: types.CREATE_BULK_ORDER_FAILURE });
    console.log(e);
  }
}

function* handleCreateInvoicesInMerit(action: ActionType) {
  try {
    const body = {
      rec_adv_ids: action.payload,
    };
    yield call(apiClient.post, "telema-receiving-advices/merit-invoice", body);
    yield put(fetchRecAdvs());
    yield put({ type: types.CREATE_INVOICES_IN_MERIT_SUCCESS });
  } catch (e: any) {
    //yield put({ type: types.CREATE_BULK_ORDER_FAILURE });
    console.log(e);
    yield put({ type: types.CREATE_INVOICES_IN_MERIT_FAILURE });
  }
}

const translatorSagas = [
  takeLatest(types.FETCH_ORDERS, handleFetchOrders),
  takeLatest(types.CREATE_BULK_ORDER, handleCreateBulkOrder),
  takeLatest(types.FETCH_BULK_ORDERS, handleFetchBulkOrders),
  takeLatest(types.DOWNLOAD_BULK_ORDER_XML, handleDownloadBulkOrderXML),
  takeLatest(types.IMPORT_DELIVERY_NOTES, importDeliveryNotes),
  takeLatest(types.IMPORT_ORDERS_FROM_TELEMA, importOrdersFromTelema),
  takeLatest(types.EXPORT_BULK_ORDER_TO_TELEMA, exportBulkOrderToTelema),
  takeLatest(types.FETCH_REC_ADVS, handleFetchRecAdvs),
  takeLatest(types.IMPORT_REC_ADVS, handleImportRecAdvs),
  takeLatest(types.CREATE_INVOICES_IN_MERIT, handleCreateInvoicesInMerit),
];

export default translatorSagas;
