import RestService from "./RestService";
import Config from "../Config/Config";
import { LabelHistory, LabelHistoryDTO } from "../Models/LabelHistory";
import { store } from "../App";

const Post = (
  labelHistoryDTO: LabelHistoryDTO,
  callback: () => void,
  jwt: string | null | undefined
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(mapDTO(labelHistoryDTO)),
  };

  fetch(Config.baseURL + "/label-histories", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      callback();
      return response;
    })
    .catch((error) => {
      alert(error);
      console.log(error);
    });
};

function mapDTO(labelHistoryDTO: LabelHistoryDTO): object {
  return {
    lot: labelHistoryDTO.lot,
    best_before: labelHistoryDTO.bestBefore,
    quantity: labelHistoryDTO.quantity,
    origin: labelHistoryDTO.origin,
    label: labelHistoryDTO.labelId,
  };
}

function mapResponse(payload: any): LabelHistory[] {
  return payload.map((p: any) => {
    return {
      id: p.id,
      bestBefore: p.best_before,
      quantity: p.quantity,
      origin: p.origin,
      lot: p.lot,
      createdAt: p.created_at,
    };
  });
}

const Get = (labelId: number, revision: number, load: boolean) => {
  let filter = `?label=${labelId}&_limit=-1`;
  return RestService.get<LabelHistory>({
    endpoint: "label-histories",
    revision,
    filter,
    mapper: mapResponse,
    load,
  });
};

const LabelHistoryService = () => {
  const state = store.getState();
  const jwt = state.auth.authorizationToken;
  return {
    get: Get,
    post: (labelHistoryDTO: LabelHistoryDTO, callback: () => void) =>
      Post(labelHistoryDTO, callback, jwt),
  };
};

export default LabelHistoryService;
