import { useEffect, useState } from "react";
import { Service, ServiceStatus } from "../Models/Service";
import Config from "../Config/Config";
import { store } from "../App";

interface RestProps<T> {
  endpoint: string;
  revision?: number;
  filter?: string;
  mapper?: (payload: any) => T[];
  load?: boolean;
}

function Get<T>(props: RestProps<T>) {
  const [result, setResult] = useState<Service<T[]>>({
    status: ServiceStatus.Loading,
  });
  const state = store.getState();
  const jwt = state.auth.authorizationToken;
  let filter = props.filter ? props.filter : "";
  useEffect(() => {
    if (props.load !== undefined && !props.load) {
      console.log("Will not fetch");
      return setResult({
        status: ServiceStatus.Loading,
      });
    }
    fetch(Config.baseURL + "/" + props.endpoint + filter, {
      method: "GET",
      headers: { Authorization: `Bearer ${jwt}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        let { mapper } = props;
        const result = mapper ? mapper(response) : response;
        return setResult({ status: ServiceStatus.Loaded, payload: result });
      })
      .catch((error) => {
        console.log("Rest GetService Error", error);
        setResult({ status: ServiceStatus.Error, error });
      });
  }, [filter, props.revision, props.load]);

  return result;
}

const RestService = {
  get: Get,
};

export default RestService;
