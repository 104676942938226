import { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./AddPalletModal.style.css";
import WarehouseService from "../../Services/WarehouseService";
import { Warehouse } from "../../Models/Warehouse";
import { Producer } from "../../Models/Producer";
import { Product } from "../../Models/Product";
import { PalletDTO } from "../../Models/Pallet";
import moment from "moment";
import Utils from "../../Utils/Utils";
import { Key } from "antd/lib/table/interface";
import { Batch } from "../../Models/Batch";
import { AppState } from "../../Store";
import { connect } from "react-redux";
import {
  closeCreatePalletModal,
  createPallet,
  fetchFilteredBatches,
  fetchFilteredProducts,
  openCreatePalletModal,
} from "../../Store/Warehouse/actions";

interface AddPalletModalProps {
  selectedWarehouse?: Warehouse;
  onAdd: () => void;
  revision: number;

  products: { [key: number]: Product[] };
  producers: Producer[];
  batches: { [key: number]: Batch[] };
  openCreatePalletModal: () => void;
  closeCreatePalletModal: () => void;
  createPallet: (pallet: PalletDTO) => void;
  isLoading: boolean;
  modalVisible: boolean;
  fetchFilteredProducts: (filter: object, index: number) => void;
  fetchFilteredBatches: (filter: object, index: number) => void;
  isFetchingProducts: boolean;
  isFetchingBatches: boolean;
}

interface FormItemState {
  [key: number]: any;
}
function AddPalletModal(props: AddPalletModalProps) {
  const [selectedProducers, setSelectedProducers] = useState<FormItemState>({});
  const [selectedProducts, setSelectedProducts] = useState<FormItemState>({});
  const [form] = Form.useForm();
  const warehouseService = WarehouseService.get();
  const {
    openCreatePalletModal: showModal,
    closeCreatePalletModal: handleCancel,
    createPallet,
    isLoading,
    modalVisible,
    fetchFilteredProducts,
    fetchFilteredBatches,
    isFetchingProducts,
    isFetchingBatches,
  } = props;

  useEffect(() => {
    form.resetFields();
  }, [modalVisible]);
  const handleOk = () => {
    form.submit();
  };

  function handleFinish(values: any) {
    const dto: PalletDTO = {
      warehouseId: values.warehouse,
      type: values.type,
      numberOfPallets: values.numberOfPallets,
      storedAt: values.storedAt,
      palletItems: values.products.map((p: any) => {
        return {
          quantity: p.quantity,
          productId: p.product,
          batchId: p.batch,
        };
      }),
    };
    createPallet(dto);
  }

  function getWarehouses(): Warehouse[] {
    return Utils.getDataFromService(warehouseService);
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 4 },
    },
  };

  const updateProducers = (
    productIndex: number | undefined, // index of product that is dynamically created 1st = 0 etc
    producerId: number,
    producerValues: object
  ) => {
    if (productIndex === undefined) {
      return;
    }
    let newProducers = selectedProducers;
    newProducers[productIndex] = producerValues;
    setSelectedProducers(newProducers);
    form.setFields([
      { name: ["products", productIndex, "product"], value: undefined },
    ]);
    form.setFieldsValue({});
    const filter = {
      _where: {
        "producer.id": [producerId],
      },
    };
    fetchFilteredProducts(filter, productIndex);
  };

  const updateProducts = (
    productIndex: number | undefined, // index of product that is dynamically created 1st = 0 etc
    productId: number,
    productValues: object
  ) => {
    if (productIndex === undefined) {
      return;
    }
    let newProducts = selectedProducts;
    newProducts[productIndex] = productValues;
    setSelectedProducts(newProducts);
    form.setFields([
      { name: ["products", productIndex, "batch"], value: undefined },
    ]);

    form.setFieldsValue({});

    const producerId = selectedProducers[productIndex].value;
    const filter = {
      _where: [{ "owner.id": [producerId] }, { "product.id": [productId] }],
    };
    fetchFilteredBatches(filter, productIndex);
  };

  const DynamicProductList = () => {
    const { products, producers, batches } = props;
    return (
      <Form.List name="products">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Form.Item
                {...(name === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={name === 0 ? "Tooted" : ""}
                required={false}
                key={key}
              >
                <Form.Item
                  {...restField}
                  name={[name, "producer"]}
                  fieldKey={[fieldKey as Key, "producer"]}
                  rules={[{ required: true, message: "Vali omanik" }]}
                  className={"dynamic-tight-form-item "}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Vali omanik..."
                    optionFilterProp="children"
                    onChange={(a, b) => updateProducers(name, a, b)}
                    filterOption={(input, option) =>
                      option?.title
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {producers
                      .filter((p: Producer) => {
                        return p.tags.find((t) => t.title === "Warehouse");
                      })
                      .map((p) => {
                        return (
                          <Select.Option
                            key={"producer-" + p.id}
                            value={p.id}
                            title={p.name}
                          >
                            {p.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "product"]}
                  fieldKey={[fieldKey as Key, "product"]}
                  rules={[{ required: true, message: "Vali toode" }]}
                  className={"dynamic-tight-form-item "}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Vali artikkel"
                    optionFilterProp="children"
                    loading={isFetchingProducts}
                    showArrow={true}
                    onChange={(a, b) => updateProducts(name, a, b)}
                    filterOption={(input, option) =>
                      option?.title
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {products[name] &&
                      products[name]
                        .sort((a: Product, b: Product) =>
                          a.title
                            .trim()
                            .toLowerCase()
                            .localeCompare(b.title.trim().toLowerCase())
                        )
                        .map((p: Product) => {
                          return (
                            <Select.Option
                              key={"product-" + p.id}
                              value={p.id}
                              title={p.title}
                            >
                              {p.title}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "batch"]}
                  fieldKey={[fieldKey as Key, "batch"]}
                  rules={[{ required: true, message: "Vali partii" }]}
                  className={"dynamic-tight-form-item "}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Vali Partii"
                    optionFilterProp="children"
                    loading={isFetchingBatches}
                    showArrow={true}
                    filterOption={(input, option) =>
                      option?.title
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {batches[name] &&
                      batches[name]
                        .sort((a: Batch, b: Batch) =>
                          a.createdAt
                            .trim()
                            .toLowerCase()
                            .localeCompare(b.createdAt.trim().toLowerCase())
                        )
                        .map((b: Batch) => {
                          return (
                            <Select.Option
                              key={b.id}
                              value={b.id}
                              title={b.lot}
                            >
                              Lot: {b.lot}, lisatud süsteemi:{" "}
                              {moment(b.createdAt).format("DD.MM.YYYY")}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </Form.Item>
                <Form.Item className={"dynamic-tight-form-item "}>
                  <Form.Item
                    {...restField}
                    noStyle={true}
                    style={{ width: "100%" }}
                    name={[name, "quantity"]}
                    fieldKey={[fieldKey as Key, "quantity"]}
                    rules={[{ required: true, message: "Lisa kogus" }]}
                  >
                    <InputNumber placeholder={"Kogus"} min={0} />
                  </Form.Item>{" "}
                  <span>kg</span>
                </Form.Item>
                {name > 0 && (
                  <Row justify={"end"}>
                    <Button danger={true} onClick={() => remove(name)}>
                      Kustuta toode
                    </Button>
                  </Row>
                )}
              </Form.Item>
            ))}
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Lisa toode
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  const getDefaultValue = (
    arr: any[],
    selectedValue?: any
  ): number | undefined => {
    if (selectedValue !== undefined) {
      return selectedValue.id;
    }
    if (arr.length > 0) {
      return arr[0].id;
    }
    return undefined;
  };

  const ModalForm = () => {
    let defaultWarehouse = getDefaultValue(
      getWarehouses(),
      props.selectedWarehouse
    );
    return (
      <Form
        form={form}
        preserve={true}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        initialValues={{
          type: "EUR",
          products: [""],
          warehouse: defaultWarehouse,
          numberOfPallets: 1,
          storedAt: moment(),
        }}
        onFinish={handleFinish}
      >
        <Form.Item label="Aluse tüüp" name="type">
          <Radio.Group>
            <Radio.Button value="EUR">EUR</Radio.Button>
            <Radio.Button value="FIN">FIN</Radio.Button>
            <Radio.Button value="other">Muu</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Aluste arv" name="numberOfPallets">
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item label="Ladu" name={"warehouse"}>
          <Select style={{ width: "100%" }}>
            {getWarehouses().map((wh) => (
              <Select.Option key={wh.id} value={wh.id}>
                {wh.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Lattu lisatud" name="storedAt">
          <DatePicker />
        </Form.Item>
        <DynamicProductList />
      </Form>
    );
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        Lisa alus(eid)
      </Button>
      <Modal
        width={800}
        title="Lisa alus(eid)"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Lisa"}
        cancelText={"Tühista"}
        confirmLoading={isLoading}
        destroyOnClose={true}
      >
        <ModalForm />
      </Modal>
    </>
  );
}

const mapState = (state: AppState) => ({
  producers: state.warehouse.producer.producers,
  products: state.warehouse.product.filteredProducts,
  batches: state.warehouse.batch.filteredBatches,
  isLoading: state.warehouse.pallet.creatingPallet,
  modalVisible: state.warehouse.pallet.createPalletModalOpen,
  isFetchingProducts: state.warehouse.product.fetchingFilteredProducts,
  isFetchingBatches: state.warehouse.batch.fetchingFilteredBatches,
});

const mapDispatch = {
  openCreatePalletModal,
  closeCreatePalletModal,
  createPallet,
  fetchFilteredProducts,
  fetchFilteredBatches,
};

export default connect(mapState, mapDispatch)(AddPalletModal);
