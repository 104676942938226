interface ServiceInit {
    status: ServiceStatus.Init;
}
interface ServiceLoading {
    status: ServiceStatus.Loading;
}
interface ServiceLoaded<T> {
    status: ServiceStatus.Loaded;
    payload: T;
}
interface ServiceError {
    status: ServiceStatus.Error;
    error: Error;
}

export enum ServiceStatus {
    Init,
    Loading,
    Loaded,
    Error
}
export type Service<T> = ServiceInit | ServiceLoading | ServiceLoaded<T> | ServiceError;
