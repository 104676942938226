import { Layout } from "antd";
import HeaderMenu from "./Components/HeaderMenu/HeaderMenu";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./Auth/PrivateRoute";
import WarehouseView from "./Views/Warehouse/WarehouseView";
import ProducersView from "./Views/Producers/ProducersView";
import ProductsView from "./Views/Products/ProductsView";
import LabelsView from "./Views/Labels/LabelsView";
import LoginView from "./Views/Login/LoginView";
import organicJPG from "./Assets/organic.jpg";
import organicJPGR90 from "./Assets/organic_90.jpg";
import organic203JPG from "./Assets/organic_203.jpg";
import testLabel from "./Assets/test_label.png";
import React from "react";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { AppState } from "./Store";
import { AuthState } from "./Store/Auth/reducers";
import { UserRoleEnum } from "./Models/Auth";
import BatchesView from "./Views/Batches/BatchesView";
import TelemaOrdersView from "./Views/TelemaOrders/TelemaOrdersView";
import TelemaBulkOrdersView from "./Views/TelemaBulkOrders/TelemaBulkOrdersView";
import TelemaRecAdvs from "./Views/TelemaRecAdvs/TelemaRecAdvs";
import TelemaStatistics from "./Views/TelemaStatistics/TelemaStatistics";

const { Header, Footer, Sider, Content } = Layout;

const history = createBrowserHistory();
interface AppNavigatorProps {
  auth: AuthState;
}

function AppNavigator(props: AppNavigatorProps) {
  const isAdmin = true;
  console.log("ISADMIN", isAdmin, history);
  const PrivateContent = ({ children, ...rest }: any) => {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Header style={{ background: "white" }}>
          <HeaderMenu />
        </Header>
        <Content style={{ padding: "20px 50px 20px 50px" }}>{children}</Content>
      </Layout>
    );
  };

  return (
    <Router history={history}>
      <Switch>
        <PrivateRoute exact path={"/"}>
          <Redirect to={"/home"} />
        </PrivateRoute>
        <PrivateRoute path={"/home"}>
          <PrivateContent>
            <WarehouseView />
          </PrivateContent>
        </PrivateRoute>

        <PrivateRoute exact path={"/batches"}>
          <PrivateContent>
            <BatchesView />
          </PrivateContent>
        </PrivateRoute>
        <PrivateRoute exact path={"/producers"}>
          <PrivateContent>
            <ProducersView />
          </PrivateContent>
        </PrivateRoute>
        <PrivateRoute exact path={"/products"}>
          <PrivateContent>
            <ProductsView />
          </PrivateContent>
        </PrivateRoute>
        <PrivateRoute exact path={"/labels"}>
          <PrivateContent>
            <LabelsView />
          </PrivateContent>
        </PrivateRoute>
        <PrivateRoute exact path={"/orders"}>
          <PrivateContent>
            <TelemaOrdersView />
          </PrivateContent>
        </PrivateRoute>
        <PrivateRoute exact path={"/statistics"}>
          <PrivateContent>
            <TelemaStatistics />
          </PrivateContent>
        </PrivateRoute>
        <PrivateRoute exact path={"/bulk-orders"}>
          <PrivateContent>
            <TelemaBulkOrdersView />
          </PrivateContent>
        </PrivateRoute>
        <PrivateRoute exact path={"/recadvs"}>
          <PrivateContent>
            <TelemaRecAdvs />
          </PrivateContent>
        </PrivateRoute>
        <Route path="/login">
          <LoginView />
        </Route>
        <Route path={"*"}>
          <Redirect to={"/"} />
        </Route>
      </Switch>

      <img
        src={organicJPG}
        id={"organic"}
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-200%",
          top: 0,
        }}
      ></img>
      <img
        src={organic203JPG}
        id={"organic_203"}
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-200%",
          top: 0,
        }}
      ></img>
      <img
        src={organicJPGR90}
        id={"organic_90"}
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-200%",
          top: 0,
        }}
      ></img>
      <img
        src={testLabel}
        id={"test_label"}
        style={{
          visibility: "hidden",
          position: "absolute",
          left: "-200%",
          top: 0,
        }}
      ></img>
    </Router>
  );
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AppNavigator);
