import { Producer } from "../Models/Producer";
import RestService from "./RestService";

function mapResponse(payload: any): Producer[] {
  return payload.map((p: any) => {
    return mapProducer(p);
  });
}

function mapProducer(producer: any): Producer {
  return {
    id: producer.id,
    name: producer.name,
    registrationCode: producer.registration_code,
    email: producer.email,
    phone: producer.phone,
    tags: producer.tags,
  };
}

const ProducerService = () => {
  return {
    get: (revision: number) =>
      RestService.get<Producer>({
        endpoint: "producers",
        revision,
        mapper: mapResponse,
      }),
  };
};

export default ProducerService;
