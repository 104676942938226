import React from "react";
import ProducerService from "../../Services/ProducerService";
import { ServiceStatus } from "../../Models/Service";
import { Producer } from "../../Models/Producer";
import { Table, Tag } from "antd";
import { WHTag } from "../../Models/WHTag";

interface ProducersTableProps {
  onChange: () => void;
  revision: number;
}

interface RowType extends Producer {
  counter: number;
}

const ProducersTable = (props: ProducersTableProps) => {
  let producerService = ProducerService().get(props.revision);

  const RenderTable = (producers: Producer[]) => {
    let data: RowType[] = producers.map((p, idx) => {
      return {
        ...p,
        counter: idx + 1,
      };
    });

    const columns = [
      {
        title: "#",
        dataIndex: "counter",
        key: "counter",
        render: (count: number) => <strong>{count}</strong>,
      },
      {
        title: "Nimi",
        dataIndex: "name",
        key: "name",
        render: (name: string) => <strong>{name}</strong>,
      },
      {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        render: (tags: WHTag[]) => (
          <strong>
            {tags.map((t) => (
              <Tag key={t.id}>{t.title}</Tag>
            ))}
          </strong>
        ),
      },
      {
        title: "Registrikood",
        dataIndex: "registrationCode",
        key: "registrationCode",
        render: (registrationCode: string) => (
          <strong>{registrationCode}</strong>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (email: string) => <strong>{email}</strong>,
      },
      {
        title: "Tel",
        dataIndex: "phone",
        key: "phone",
        render: (phone: string) => <strong>{phone}</strong>,
      },
    ];

    return (
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        pagination={false}
      />
    );
  };

  const Render = () => {
    if (producerService.status === ServiceStatus.Loading) {
      return <div>Loading </div>;
    }
    if (producerService.status === ServiceStatus.Loaded) {
      return RenderTable(producerService.payload);
    }
    return <div>{producerService.status}</div>;
  };

  return <Render />;
};

export default ProducersTable;
