import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { TelemaDeliveryNote } from "../../Models/TelemaDeliveryNote";
import { TelemaRecAdv } from "../../Models/TelemaRecAdv";
import { TelemaOrder } from "../../Models/TelemaOrder";
import { createInvoicesInMerit } from "../../Store/Telema/actions";
import { TelemaProduct } from "../../Models/TelemaProduct";

const _ = require("lodash");

interface SingleWarehouseProps {
  recAdvs: TelemaRecAdv[];
  importRecAdvs: () => void;
  createInvoices: (recAdVIds: number[]) => void;
}

function TelemaRecAdvsTable(props: SingleWarehouseProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  useEffect(() => {
    setSelectedRowKeys(
      props.recAdvs.filter((r) => !r.meritInvoiceCreated).map((r) => r.id)
    );
  }, [props.recAdvs]);
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    rows: any,
    info: any
  ) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys, info);
    if (info.type === "single") {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const onSelectAll = (
    selected: boolean,
    selectedRows: TelemaRecAdv[],
    changeRows: TelemaRecAdv[]
  ) => {
    if (!selected) {
      console.log("HELLO");
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(selectedRows.map((row) => row.id));
    }
    console.log("ON SELECT ALL", selected);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: onSelectAll,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnProps<TelemaRecAdv>[] = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => (
        <strong>
          {index + 1} {displayAlert(item)}
        </strong>
      ),
    },
    {
      title: "Tellimuse number",
      dataIndex: "order",
      key: "order",
      render: (order: TelemaOrder) => <strong>{order.orderNumber}</strong>,
    },
    {
      title: "Tellimuse kuupäev",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (_: string, row) => <strong>{row.order.date}</strong>,
    },
    {
      title: "Tarne kuupäev",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (deliveryDate: string) => <strong>{deliveryDate}</strong>,
    },
    {
      title: "Saateleht",
      dataIndex: "deliveryNote",
      key: "deliveryNote",
      render: (deliveryNote: TelemaDeliveryNote) => (
        <strong>{deliveryNote && deliveryNote.documentNumber}</strong>
      ),
    },
    {
      title: "Tellitud|Saadud|Vastuvõetud",
      dataIndex: "quantities",
      key: "quantities",
      render: (_: any, row) => (
        <strong>
          {row.products.map((p) => {
            return (
              <div
                key={`r${p.id}`}
                style={
                  (p.quantity === p.acceptedQuantity &&
                    p.acceptedQuantity === p.deliveredQuantity &&
                    {}) || { color: "red" }
                }
              >
                {p.quantity} | {p.deliveredQuantity} | {p.acceptedQuantity} -{" "}
                {p.productName}
              </div>
            );
          })}
        </strong>
      ),
    },
    {
      title: "Arve summa",
      dataIndex: "sum",
      key: "sum",
      render: (_: any, row) => <strong>{calculateSum(row.products)}</strong>,
      sorter: (a, b) => {
        return (
          Number(calculateSum(a.products)) - Number(calculateSum(b.products))
        );
      },
    },
    {
      title: "Merit arve loodud",
      dataIndex: "meritInvoiceCreated",
      key: "meritInvoiceCreated",
      render: (created: boolean) => (
        <span style={created ? { color: "green" } : {}}>
          {created && "Jah"}
        </span>
      ),
    },
  ];

  const displayAlert = (recadv: TelemaRecAdv) => {
    if (!recadv.deliveryNote) {
      return null;
    }

    let errors: string[] = [];

    for (const product of recadv.products) {
      let qty = product.deliveredQuantity;
      if (!product.batches) {
        continue;
      }
      let batchQty = 0;
      for (const batch of product.batches) {
        batchQty += batch.quantity;
      }
      if (qty !== batchQty) {
        errors.push(
          "Product " +
            product.productName +
            " Qty:" +
            product.quantity +
            " BQty: " +
            batchQty
        );
      }
    }

    if (errors.length > 0) {
      return <span style={{ color: "red" }}>{errors.join(", ")}</span>;
    }

    // console.log(recadv);
    return null;
  };

  const calculateSum = (products: TelemaProduct[]) => {
    let sum = 0;
    products.forEach((p) => {
      let price = p.quantity * getPrice(p.sellerGTIN) * 1.22;
      sum += price;
    });
    return sum.toFixed(2);
  };

  const getPrice: (a: string) => number = (gtin: string) => {
    const productPrices = {
      "4744559010825": "3.70", // Mango
      "4744559011211": "3.40", // Kirss
      "4744559010887": "3.40", // Ananass
      "4744559011068": "3.90", // Draakon
      "4744559010481": "5.90", // Vaarikas
      "4744559010429": "4.90", // Mustikas
      "4744559010856": "3.90", // Papaia
      "4744559010399": "3.90", // Astelpaju,
      "4744559010450": "3.90", // Pohl,
      "4744559011044": "4.30", // Maasika viilud,
      "4744559011860": "4.60", // Kultuurmustikas
    };

    // @ts-ignore
    return productPrices[gtin] ? Number(productPrices[gtin]) : 0;
  };

  const importRecAdvs = () => {
    props.importRecAdvs();
  };

  const createInvoices = () => {
    props.createInvoices(selectedRowKeys as number[]);
  };

  return (
    <>
      <Button onClick={importRecAdvs}>Impordi vastuvõtukinnitused</Button>
      <Button onClick={createInvoices}>
        Kanna arved Meritisse({selectedRowKeys.length})
      </Button>
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={props.recAdvs}
        rowKey={"id"}
        pagination={false}
        rowSelection={rowSelection}
      />
    </>
  );
}
export default TelemaRecAdvsTable;
