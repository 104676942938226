import { LabelType } from "./LabelType";
import { Product } from "./Product";

export enum ProductTempType {
  FRESH = "FRESH",
  FROZEN = "FROZEN",
}
export enum ProducerType {
  PRODUCER = "PRODUCER",
  PACKAGER = "PACKAGER",
  IMPORTER = "IMPORTER",
  EXPORTER = "EXPORTER",
  DISTRIBUTOR = "DISTRIBUTOR",
}

export interface Label {
  id: number;
  barcode: string;
  weight: number;
  title: string;
  isOrganic: boolean;
  tempType: ProductTempType;
  producerType: ProducerType;
  type: LabelType;
  titleLines: number;
  product: Product;
  hasStoneWarning: boolean;
  hasIngredients: boolean;
  ingredients: string;
  flippedHorizontally: boolean;
}

export interface LabelDTO {
  barcode: number;
  weight: number;
  title: string;
  isOrganic: boolean;
  tempType: ProductTempType;
  producerType: ProducerType;
  productId: number;
  titleLines: number;
  labelTypeId: number;
  hasStoneWarning: boolean;
  hasIngredients: boolean;
  ingredients: string;
  flippedHorizontally: boolean;
}
