import { Button, Menu, Row } from "antd";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserRoleEnum } from "../../Auth/AuthProvider";
import { connect } from "react-redux";
import { AppState } from "../../Store";
import { AuthState } from "../../Store/Auth/reducers";
import { logout } from "../../Store/Auth/actions";

interface HeaderProps {
  auth: AuthState;
  logout: () => void;
}
function HeaderMenu(props: HeaderProps) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const {
    auth: { user },
  } = props;
  const handleClick = (e: any) => {
    setActiveLink(e.key);
  };

  const handleLogOut = () => {
    props.logout();
  };
  return (
    <Row justify={"space-between"}>
      <Menu
        onClick={handleClick}
        selectedKeys={[activeLink]}
        disabledOverflow={true}
        mode="horizontal"
      >
        <Menu.Item key="/home">
          <Link to={"/home"}>Ladu</Link>
        </Menu.Item>
        {user && user?.role.type === UserRoleEnum.ADMIN && (
          <>
            <Menu.Item key="/batches">
              <Link to={"/batches"}>Partiid</Link>
            </Menu.Item>
            <Menu.Item key="/producers">
              <Link to={"/producers"}>Tootjad</Link>
            </Menu.Item>
            <Menu.Item key="/products">
              <Link to={"/products"}>Artiklid</Link>
            </Menu.Item>
            <Menu.Item key="/labels">
              <Link to={"/labels"}>Etiketid</Link>
            </Menu.Item>
            <Menu.Item key="/orders">
              <Link to={"/orders"}>Tellimused</Link>
            </Menu.Item>
            <Menu.Item key="/bulk-orders">
              <Link to={"/bulk-orders"}>Koondtellimused</Link>
            </Menu.Item>
            <Menu.Item key="/recadvs">
              <Link to={"/recadvs"}>Vastuvõtukinnitused</Link>
            </Menu.Item>
          </>
        )}
      </Menu>
      <Menu onClick={handleLogOut} mode={"horizontal"}>
        <Menu.Item key={"logout"}>
          <Button type={"link"} danger>
            Log out
          </Button>
        </Menu.Item>
      </Menu>
    </Row>
  );
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
