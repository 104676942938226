import {Warehouse} from "../../Models/Warehouse";
import {Button, Dropdown, Menu} from "antd";
import {ServiceStatus} from "../../Models/Service";
import WarehouseService from "../../Services/WarehouseService";
import {DownOutlined} from "@ant-design/icons";
import {CSSProperties} from "react";

interface WarehouseSelectionProps {
    selectedWarehouse?: Warehouse
    onSelect: (wh: Warehouse | undefined) => void
    style?: CSSProperties
}

interface WarehouseBtnProps {
    warehouse: Warehouse;
}

function WarehouseSelection(props: WarehouseSelectionProps) {
    const service = WarehouseService.get()

    const RenderWarehouseMenuItem = (btnProps: WarehouseBtnProps) => {
        const {warehouse} = btnProps
        return <Menu.Item key={warehouse.id} onClick={() => props.onSelect(warehouse)}>
            <strong>{warehouse.name}</strong>
        </Menu.Item>
    }

    function getWarehouseList(): Warehouse[] {
        let warehouses: Warehouse[] = []
        if (service.status === ServiceStatus.Loaded) {
            warehouses = service.payload
        }
        return warehouses
    }

    const DropdownMenu = () => {
        let warehouses = getWarehouseList()

        return <Menu style={props.style}>
            <Menu.Item key={"all"} onClick={() => props.onSelect(undefined)}>
                <strong>Kõik</strong>
            </Menu.Item>
            {warehouses.map((wh, idx) => {
                return RenderWarehouseMenuItem({warehouse: wh})
            })}
        </Menu>
    }

    const RenderWarehouseDropdown = () => {
        console.log(props)
        return    <Dropdown overlay={DropdownMenu}>
            <Button block >
                {props.selectedWarehouse !== undefined ? props.selectedWarehouse.name : 'Kõik laod'} <DownOutlined />
            </Button>
        </Dropdown>
    }

    return <RenderWarehouseDropdown/>
}

export default WarehouseSelection
