import {
  call,
  delay,
  select,
  race,
  take,
  takeLatest,
  put,
} from "redux-saga/effects";
import * as types from "./types";
import { REHYDRATE } from "redux-persist";
import { AppState } from "..";
import apiClient from "../api";

function* handleAppMounted() {
  yield race({
    rehydrate: take(REHYDRATE),
    timeout: delay(500),
  });
  const authToken: string | null = yield select(
    (state: AppState) => state.auth.authorizationToken
  );
  yield call(apiClient.setAccessToken, authToken);
  yield put({ type: types.SET_APP_MOUNTED, mounted: true });
}

const settingSagas = [takeLatest(types.APP_MOUNTED, handleAppMounted)];

export default settingSagas;
