import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./types";
import { AuthActionType } from "../../Models/Auth";
import ApiClient from "../../Utils/ApiClient";
import Config from "../../Config/Config";
import { get } from "lodash";
import apiClient from "../api";

function* handleAuthenticateUser(action: AuthActionType) {
  try {
    const { userCredentials } = action;
    const authApiClient = new ApiClient(Config.baseURL);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const requestBody = {
      identifier: userCredentials?.identifier,
      password: userCredentials?.password,
    };
    const { data } = yield call(
      authApiClient.post,
      "/auth/local",
      requestBody,
      config
    );

    const jwt = get(data, "jwt", null);
    const user = get(data, "user", null);
    yield call(apiClient.setAccessToken, jwt ? `Bearer ${jwt}` : jwt);

    if (jwt === null || user === null) {
      yield put({ type: types.AUTHENTICATE_USER_FAILURE });
    } else {
      yield put({ type: types.AUTHENTICATE_USER_SUCCESS, user, token: jwt });
    }

    /*const accessToken = get(data, "access_token", null);
    const tokenType = get(data, "token_type", null);
    const token = `${tokenType} ${accessToken}`;
    yield call(apiClient.setAccessToken, token);
    yield put(fetchAccount());
    yield take([
      settingTypes.FETCH_ACCOUNT_SUCCESS,
      settingTypes.FETCH_ACCOUNT_FAILURE,
    ]);
    yield put({ type: types.AUTHENTICATE_USER_SUCCESS, token });
    NavigationService.navigate("Intro");
    yield put(clearFormData());*/
  } catch (error) {
    console.log(error);
    /*handleError({
      type: ActionType.LOGIN,
      error,
      action,
      errorTypes: [types.AUTHENTICATE_USER_FAILURE],
    });*/
  }
}

function* handleLogout() {
  console.log("Logout");
  /*yield put(clearData());
  yield put(clearDevices());
  yield put(clearFormData());
  NavigationService.navigate('Main');*/
}

const authSagas = [
  takeLatest(types.AUTHENTICATE_USER, handleAuthenticateUser),
  takeLatest(types.LOGOUT, handleLogout),
];

export default authSagas;
