import { Producer } from "./Producer";
import { Product } from "./Product";
import ActionType from "./ActionType";

export interface Batch {
  id: number;
  lot: string;
  supplier: Producer;
  createdAt: string;
  product: Product;
  quantity: number;
  bestBefore: string;
  parentBatches: Batch[];
  childBatches: Batch[];
  owner: Producer;
  origin: string;
  documents: BatchDocument[];
}

export interface BatchDocument {
  type: BatchDocumentType;
  name: string;
  downloadUrl: string;
}

export interface BatchDTO {
  lot: string;
  producerId: number;
  productId: number;

  ownerId: number;
  quantity: number;
  bestBefore: string;
  origin: string;
}

export enum BatchDocumentType {
  invoice = "invoice",
  billOfDelivery = "billOfDelivery",
  analysis = "analysis",
  certificate = "certificate",
  other = "other",
}

export const allBatchDocumentTypes = Object.keys(BatchDocumentType).filter(
  (item) => {
    return isNaN(Number(item));
  }
);
export interface BatchActionType extends ActionType {
  batch?: BatchDTO;
}
