import * as types from "./types";
import { SettingsType } from "../../Models/ActionType";

export interface SettingsState {
  mounted: boolean;
}
const initialState = {
  mounted: false,
};

const settings = (
  state: SettingsState = initialState,
  action: SettingsType
): SettingsState => {
  switch (action.type) {
    case types.SET_APP_MOUNTED:
      return {
        ...state,
        mounted: !!action.mounted,
      };
    default:
      return state;
  }
};

export default settings;
