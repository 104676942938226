export const CREATE_BATCH = "Warehouse.CREATE_BATCH";
export const CREATE_BATCH_SUCCESS = "Warehouse.CREATE_BATCH_SUCCESS";
export const CREATE_BATCH_FAILURE = "Warehouse.CREATE_BATCH_FAILURE";

export const FETCH_BATCHES = "Warehouse.FETCH_BATCHES";
export const FETCH_BATCHES_SUCCESS = "Warehouse.FETCH_BATCHES_SUCCESS";
export const FETCH_BATCHES_FAILURE = "Warehouse.FETCH_BATCHES_FAILURE";

export const FETCH_FILTERED_BATCHES = "Warehouse.FETCH_FILTERED_BATCHES";
export const FETCH_FILTERED_BATCHES_SUCCESS =
  "Warehouse.FETCH_FILTERED_BATCHES_SUCCESS";
export const FETCH_FILTERED_BATCHES_FAILURE =
  "Warehouse.FETCH_FILTERED_BATCHES_FAILURE";

export const OPEN_CREATE_BATCH_MODAL = "Warehouse.OPEN_CREATE_BATCH_MODAL";
export const CLOSE_CREATE_BATCH_MODAL = "Warehouse.CLOSE_CREATE_BATCH_MODAL";

export const FETCH_PRODUCERS = "Warehouse.FETCH_PRODUCERS";
export const FETCH_PRODUCERS_SUCCESS = "Warehouse.FETCH_PRODUCERS_SUCCESS";
export const FETCH_PRODUCERS_FAILURE = "Warehouse.FETCH_PRODUCERS_FAILURE";

export const FETCH_PRODUCTS = "Warehouse.FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "Warehouse.FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "Warehouse.FETCH_PRODUCTS_FAILURE";

export const FETCH_FILTERED_PRODUCTS = "Warehouse.FETCH_FILTERED_PRODUCTS";
export const FETCH_FILTERED_PRODUCTS_SUCCESS =
  "Warehouse.FETCH_FILTERED_PRODUCTS_SUCCESS";
export const FETCH_FILTERED_PRODUCTS_FAILURE =
  "Warehouse.FETCH_FILTERED_PRODUCTS_FAILURE";

export const FETCH_PALLETS = "Warehouse.FETCH_PALLETS";
export const FETCH_PALLETS_SUCCESS = "Warehouse.FETCH_PALLETS_SUCCESS";
export const FETCH_PALLETS_FAILURE = "Warehouse.FETCH_PALLETS_FAILURE";
export const CREATE_PALLET = "Warehouse.CREATE_PALLET";
export const CREATE_PALLET_SUCCESS = "Warehouse.CREATE_PALLET_SUCCESS";
export const CREATE_PALLET_FAILURE = "Warehouse.CREATE_PALLET_FAILURE";

export const OPEN_CREATE_PALLET_MODAL = "Warehouse.OPEN_CREATE_PALLET_MODAL";
export const CLOSE_CREATE_PALLET_MODAL = "Warehouse.CLOSE_CREATE_PALLET_MODAL";

export const EDIT_PALLET = "Warehouse.EDIT_PALLET";
export const EDIT_PALLET_SUCCESS = "Warehouse.EDIT_PALLET_SUCCESS";
export const EDIT_PALLET_FAILURE = "Warehouse.EDIT_PALLET_FAILURE";

export const EDIT_PALLET_ITEM = "Warehouse.EDIT_PALLET";
export const EDIT_PALLET_ITEM_SUCCESS = "Warehouse.EDIT_PALLET_ITEM_SUCCESS";
export const EDIT_PALLET_ITEM_FAILURE = "Warehouse.EDIT_PALLET_ITEM_FAILURE";
