import useAuth from "./AuthProvider";
import { Redirect, Route } from "react-router-dom";
import { AppState } from "../Store";
import { connect } from "react-redux";

function PrivateRoute({ children, auth, ...rest }: any) {
  console.log(children, rest);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
