import React, { useEffect } from "react";
import { AppState } from "../../Store";
import { connect } from "react-redux";
import {
  createBulkOrder,
  downloadBulkOrderXML, exportBulkOrderToTelema,
  fetchBulkOrders,
  fetchOrders, importDeliveryNotes,
} from "../../Store/Telema/actions";
import TelemaOrdersTable from "../../Components/TelemaOrdersTable/TelemaOrdersTable";
import { TelemaOrder } from "../../Models/TelemaOrder";
import {
  TelemaBulkOrder,
  TelemaBulkOrderDTO,
} from "../../Models/TelemaBulkOrder";
import TelemaBulkOrdersTable from "../../Components/TelemaBulkOrdersTable/TelemaBulkOrdersTable";

interface OrdersProps {
  fetchBulkOrders: () => void;
  downloadBulkOrderXML: (id: number) => void;
  orders: TelemaBulkOrder[];
  importDeliveryNotes: () => void;
  exportBulkOrderToTelema: (id: number) => void
}
function TelemaOrdersView(props: OrdersProps) {
  useEffect(() => {
    props.fetchBulkOrders();
  }, []);

  return (
    <>
      <TelemaBulkOrdersTable
        orders={props.orders}
        downloadXML={props.downloadBulkOrderXML}
        importDeliveryNotes={props.importDeliveryNotes}
        exportDeliveryNote={props.exportBulkOrderToTelema}
      />
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  orders: state.telema.bulkOrders.sort(
    // @ts-ignore
    (a, b) => b.id - a.id
  ),
});

const mapDispatchToProps = {
  fetchBulkOrders,
  downloadBulkOrderXML,
  importDeliveryNotes,
  exportBulkOrderToTelema
};
export default connect(mapStateToProps, mapDispatchToProps)(TelemaOrdersView);
