import { useEffect, useState } from "react";
import { Service, ServiceStatus } from "../Models/Service";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { Device } from "zebra-browser-print-wrapper/lib/types";
import {
  Orientation,
  ZebraBarcode,
  ZebraConfig,
  ZebraGraphicBox,
  ZebraImage,
  ZebraItem,
  ZebraText,
  ZebraTextBlock,
} from "../Models/Zebra";

interface DefaultPrinterProps {
  revision: number;
}

function GetDefaultPrinter<Device>(props: DefaultPrinterProps) {
  const [result, setResult] = useState<Service<Device>>({
    status: ServiceStatus.Loading,
  });

  const wrapper = new ZebraBrowserPrintWrapper();

  useEffect(() => {
    wrapper
      .getDefaultPrinter()
      .then((response: Device | any) => {
        console.log(response);
        return setResult({ status: ServiceStatus.Loaded, payload: response });
      })
      .catch((error) => {
        console.log("PrinterService GetDefaultPrinter error", error);
        setResult({ status: ServiceStatus.Error, error });
      });
  }, [props.revision]);

  return result;
}

const printLabel = async (zpl: string) => {
  try {
    console.log("PRint");
    const wrapper = new ZebraBrowserPrintWrapper();
    console.log("wrapper");

    const defaultPrinter = await wrapper.getDefaultPrinter();
    console.log("default");
    wrapper.setPrinter(defaultPrinter);

    console.log("status");
    const printerStatus = await wrapper.checkPrinterStatus();
    // console.log(printerStatus)
    console.log("ready");

    if (printerStatus.isReadyToPrint) {
      console.log("isready");
      console.log(defaultPrinter);
      // ZPL script to print a simple barcode
      await wrapper.print(zpl);
    } else {
      console.log("Error/s", printerStatus.errors);
    }
  } catch (error: any) {
    console.log(error);
  }
};

const labelStart = "^XA";
const labelEnd = "^XZ";
const commandUTF8 = "^CI28^JUS";
const commandStart = "^FO ";
const commandEnd = "^FS";

const zebraTextCommand = (text: ZebraText): string => {
  if (text.font === undefined) {
    const position = `${text.position.x}, ${text.position.y}`;
    const font = `^A0, ${text.size.height}, ${
      text.size.width ? text.size.width : ""
    }`;
    //const font = `^A@N,${text.size.height},${text.size.width ? text.size.width : ""},E:LOT000.FNT`;
    const content = `^FD${text.content}`;
    return commandStart + position + font + content + commandEnd;
  }
  // TODO: Custom font
  return "";
};

const zebraTextBlockCommand = (text: ZebraTextBlock): string => {
  if (text.font === undefined) {
    const position = `${text.position.x}, ${text.position.y}`;
    const textBlock = `^FB${text.maxWidth},${text.maxLines},,${text.alignment}`;
    const font = `^A0, ${text.size.height}, ${
      text.size.width ? text.size.width : ""
    }`;
    //const font = `^A@N,${text.size.height},${text.size.width ? text.size.width : ""},E:LOT000.FNT`;
    const content = `^FD${text.content}`;
    return commandStart + position + textBlock + font + content + commandEnd;
  }
  // TODO: Custom font
  return "";
};

const ZebraGraphicBoxCommand = (box: ZebraGraphicBox): string => {
  const position = `${box.position.x}, ${box.position.y}`;
  const b = `^GB${box.size.width},${box.size.height},${box.thickness}`;
  return commandStart + position + b + commandEnd;
};

//f0   x    y.    3  type.height           code
//^FO 60, 120 ^BY 3 ^BC , 60, , , , A ^FD 1234ABC ^FS

const zebraBarCodeCommand = (barcode: ZebraBarcode): string => {
  const position = `${barcode.position.x}, ${barcode.position.y}`;
  const code = `^BY 5 ^BE , ${barcode.size.height}, ,`;
  const content = `^FD${barcode.code}`;
  return commandStart + position + code + content + commandEnd;
};

const ZebraImageCommand = (image: ZebraImage): string => {
  const position = `${image.position.x}, ${image.position.y}`;
  const content = image.content;
  return commandStart + position + content + commandEnd;
};

const buildPart = (part: ZebraItem): string => {
  if (part.type === "ZebraText") {
    return zebraTextCommand(part);
  }

  if (part.type === "ZebraTextBlock") {
    return zebraTextBlockCommand(part);
  }
  if (part.type === "ZebraBarcode") {
    return zebraBarCodeCommand(part);
  }
  if (part.type === "ZebraGraphicBox") {
    return ZebraGraphicBoxCommand(part);
  }

  if (part.type === "ZebraImage") {
    return ZebraImageCommand(part);
  }
  return "";
};

const buildLabel = (config: ZebraConfig, parts: ZebraItem[]): string => {
  var newParts: ZebraItem[] = parts;
  var labelWidth =
    config.orientation === Orientation.ROTATED_90 ||
    config.orientation === Orientation.ROTATED_270
      ? config.labelHeight
      : config.labelWidth;
  var labelHeight =
    config.orientation === Orientation.ROTATED_90 ||
    config.orientation === Orientation.ROTATED_270
      ? config.labelWidth
      : config.labelHeight;
  if (config.orientation === Orientation.ROTATED_90) {
    newParts = parts.map((part) => {
      const size =
        part.type === "ZebraGraphicBox"
          ? { width: part.size.height, height: part.size.width! }
          : part.size;
      const position =
        part.type === "ZebraGraphicBox"
          ? {
              x: labelWidth - part.position.y - part.size.height / 2,
              y: part.position.x,
            }
          : {
              x: labelWidth - part.position.y - part.size.height,
              y: part.position.x,
            };

      return {
        ...part,
        position,
        size,
      };
    });
  }
  if (config.orientation === Orientation.ROTATED_270) {
    newParts = parts.map((part) => {
      return {
        ...part,
        position: { x: part.position.y, y: labelHeight - part.position.x },
      };
    });
  }
  console.log(config.orientation === Orientation.ROTATED_90);
  const content = newParts.map((part) => buildPart(part)).join("\n");
  const invert = config.flippedHorizontally ? "^PON" : "^POI";
  const quantity = "^PQ" + config.quantity;
  return (
    labelStart +
    quantity +
    invert +
    `^LH0,10^PW${labelWidth}` +
    `^FW${config.orientation}` +
    commandUTF8 +
    content +
    labelEnd
  );
};

const ZebraCommands = {
  text: zebraTextCommand,
};

const PrinterService = {
  GetDefaultPrinter: (revision: number) =>
    GetDefaultPrinter<Device>({ revision }),
  buildLabel,
  printLabel,
};

export default PrinterService;
