import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./AddBatchModal.style.css";
import { Producer } from "../../Models/Producer";
import { Product } from "../../Models/Product";
import { BatchDTO } from "../../Models/Batch";
import { connect } from "react-redux";
import { AppState } from "../../Store";
import {
  closeCreateBatchModal,
  createBatch,
  fetchProducers,
  fetchProducts,
  openCreateBatchModal,
} from "../../Store/Warehouse/actions";

interface AddBatchModalProps {
  createBatch: (batch: BatchDTO) => void;
  openCreateBatchModal: () => void;
  closeCreateBatchModal: () => void;
  batchModalOpen: boolean;
  creatingBatch: boolean;
  fetchProducts: () => void;
  products: Product[];
  fetchProducers: () => void;
  producers: Producer[];
}

interface ProducerState {
  [key: number]: any;
}
function AddBatchModal(props: AddBatchModalProps) {
  const [form] = Form.useForm();
  const producerId = Form.useWatch("producerId", form);

  useEffect(() => {
    props.fetchProducts();
    props.fetchProducers();
  }, []);

  const showModal = () => {
    props.openCreateBatchModal();
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    props.closeCreateBatchModal();
  };

  function handleFinish(values: any) {
    const dto: BatchDTO = {
      lot: values.lot,
      producerId: values.producerId,
      productId: values.productId,
      quantity: values.quantity,
      bestBefore: values.bestBefore,
      ownerId: values.ownerId,
      origin: values.origin,
    };
    const { createBatch } = props;
    createBatch(dto);
    //batchService.post(dto, handleBatchCreated);
  }

  function getProducers(): Producer[] {
    return props.producers;
  }

  function getProducts(): Product[] {
    return props.products;
  }

  const ModalForm = () => {
    return (
      <Form
        form={form}
        preserve={true}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleFinish}
      >
        <Form.Item
          label="Kauba omanik"
          name={"ownerId"}
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Select style={{ width: "100%" }}>
            {getProducers()
              .filter((p) => {
                return p.tags.find((t) => t.title === "Warehouse");
              })
              .map((p) => {
                return (
                  <Select.Option
                    key={"producer-" + p.id}
                    value={p.id}
                    title={p.name}
                  >
                    {p.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tootja"
          name={"producerId"}
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Select style={{ width: "100%" }}>
            {getProducers()
              .filter((p) => {
                return p.tags.find((t) => t.title === "Supplier");
              })
              .map((p) => {
                return (
                  <Select.Option
                    key={"producer-" + p.id}
                    value={p.id}
                    title={p.name}
                  >
                    {p.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Artikkel"
          name={"productId"}
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Select style={{ width: "100%" }}>
            {getProducts()
              .sort((a, b) =>
                a.title
                  .trim()
                  .toLowerCase()
                  .localeCompare(b.title.trim().toLowerCase())
              )
              .filter((p) => {
                return p.producer.id === producerId;
              })
              .map((p) => {
                return (
                  <Select.Option
                    key={"product-" + p.id}
                    value={p.id}
                    title={p.title}
                  >
                    {p.title}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item label="Lot" name="lot">
          <Input />
        </Form.Item>
        <Form.Item label="Kogus (kg)" name="quantity">
          <Input type={"number"} />
        </Form.Item>
        <Form.Item
          label="Parim enne"
          name="bestBefore"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <DatePicker placeholder={"Parim enne"} format={"DD.MM.YYYY"} />
        </Form.Item>
        <Form.Item
          label="Päritolu"
          name="origin"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Select style={{ width: "100%" }}>
            <Select.Option value={"Eesti"}>Eesti</Select.Option>
            <Select.Option value={"Eesti/Läti/Ukraina"}>
              Eesti/Läti/Ukraina
            </Select.Option>
            <Select.Option value={"Guatemala"}>Guatemala</Select.Option>
            <Select.Option value={"Hiina"}>Hiina</Select.Option>
            <Select.Option value={"India"}>India</Select.Option>
            <Select.Option value={"Kreeka"}>Kreeka</Select.Option>
            <Select.Option value={"Läti"}>Läti</Select.Option>
            <Select.Option value={"Läti/Ukraina"}>Läti/Ukraina</Select.Option>
            <Select.Option value={"Leedu"}>Leedu</Select.Option>
            <Select.Option value={"Maroko"}>Maroko</Select.Option>
            <Select.Option value={"Peruu"}>Peruu</Select.Option>
            <Select.Option value={"Poola"}>Poola</Select.Option>
            <Select.Option value={"Rootsi"}>Rootsi</Select.Option>
            <Select.Option value={"Serbia"}>Serbia</Select.Option>
            <Select.Option value={"Soome"}>Soome</Select.Option>
            <Select.Option value={"Türgi"}>Türgi</Select.Option>
            <Select.Option value={"Ukraina"}>Ukraina</Select.Option>
            <Select.Option value={"Vietnam"}>Vietnam</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        Lisa uus partii
      </Button>
      <Modal
        width={800}
        title="Lisa uus partii"
        visible={props.batchModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Lisa"}
        cancelText={"Tühista"}
        confirmLoading={props.creatingBatch}
        destroyOnClose={true}
      >
        <ModalForm />
      </Modal>
    </>
  );
}
const mapStateToProps = (state: AppState) => ({
  creatingBatch: state.warehouse.batch.creatingBatch,
  batchModalOpen: state.warehouse.batch.isCreateBatchModalOpen,
  products: state.warehouse.product.products,
  producers: state.warehouse.producer.producers,
});
const mapDispatchToProps = {
  createBatch,
  openCreateBatchModal,
  closeCreateBatchModal,
  fetchProducers,
  fetchProducts,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBatchModal);
