import ActionType from "../../Models/ActionType";
import * as types from "./types";

export interface FileState {
  fileModalOpen: boolean;
  uploadingFile: boolean;
}
const initialState = {
  fileModalOpen: false,
  uploadingFile: false,
};

const file = (
  state: FileState = initialState,
  action: ActionType
): FileState => {
  switch (action.type) {
    case types.UPLOAD_FILE: {
      return {
        ...state,
        uploadingFile: true,
      };
    }
    case types.UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        uploadingFile: false,
        fileModalOpen: false,
      };
    }
    case types.UPLOAD_FILE_FAILURE: {
      return {
        ...state,
        uploadingFile: false,
      };
    }
    case types.OPEN_MODAL: {
      return {
        ...state,
        fileModalOpen: true,
      };
    }
    case types.CLOSE_MODAL: {
      return {
        ...state,
        fileModalOpen: false,
      };
    }
    default:
      return state;
  }
};

export default file;
