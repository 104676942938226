export const FETCH_ORDERS = "Telema.FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "Telema.FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "Telema.FETCH_ORDERS_FAILURE";

export const CREATE_BULK_ORDER = "Telema.CREATE_BULK_ORDER";
export const CREATE_BULK_ORDER_SUCCESS = "Telema.CREATE_BULK_ORDER_SUCCESS";
export const CREATE_BULK_ORDER_FAILURE = "Telema.CREATE_BULK_ORDER_FAILURE";

export const FETCH_BULK_ORDERS = "Telema.FETCH_BULK_ORDERS";
export const FETCH_BULK_ORDERS_SUCCESS = "Telema.FETCH_BULK_ORDERS_SUCCESS";
export const FETCH_BULK_ORDERS_FAILURE = "Telema.FETCH_BULK_ORDERS_FAILURE";

export const FETCH_REC_ADVS = "Telema.FETCH_REC_ADVS";
export const FETCH_REC_ADVS_SUCCESS = "Telema.FETCH_REC_ADVS_SUCCESS";
export const FETCH_REC_ADVS_FAILURE = "Telema.FETCH_REC_ADVS_FAILURE";
export const IMPORT_REC_ADVS = "Telema.IMPORT_REC_ADVS";

export const DOWNLOAD_BULK_ORDER_XML = "Telema.DOWNLOAD_BULK_ORDER_XML";
export const IMPORT_DELIVERY_NOTES = "Telema.IMPORT_DELIVERY_NOTES"
export const EXPORT_BULK_ORDER_TO_TELEMA = "Telema.EXPORT_BULK_ORDER_TO_TELEMA"
export const IMPORT_ORDERS_FROM_TELEMA = "Telema.IMPORT_ORDERS_FROM_TELEMA"

export const CREATE_INVOICES_IN_MERIT = "Telema.CREATE_INVOICES_IN_MERIT";
export const CREATE_INVOICES_IN_MERIT_SUCCESS = "Telema.CREATE_INVOICES_IN_MERIT_SUCCESS";
export const CREATE_INVOICES_IN_MERIT_FAILURE = "Telema.CREATE_INVOICES_IN_MERIT_FAILURE";
