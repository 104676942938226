import {useState} from "react";
import {Button, DatePicker, Form, Modal} from "antd";
import "./AddPalletModal.style.css"
import PalletService from "../../Services/PalletService";
import moment from "moment";
import {Pallet} from "../../Models/Pallet";

interface DeletePalletModalProps {
    pallet: Pallet;
    onDelete: () => void
}
function DeletePalletModal(props: DeletePalletModalProps) {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [wasDeleted, setWasDeleted] = useState(false)
    const [form] = Form.useForm();
    const palletService = PalletService()

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        form.submit()
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };

    const handlePalletDeleted = () => {
        setWasDeleted(true)
        setVisible(false)
        setConfirmLoading(false)
    }

    function handleFinish(values: any) {
        let dto = {
            id: props.pallet.id,
            removedAt: values.removedAt
        }
        setConfirmLoading(true)
        palletService.delete(dto, handlePalletDeleted)
    }

    const handleModalDestroy = () => {
        if (wasDeleted) {
            props.onDelete()
        }
    }

    const ModalForm = () => {
        return <Form
            form={form}
            preserve={false}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            layout="horizontal"
            initialValues={{ removedAt: moment()}}
            onFinish={handleFinish}
        >
            <Form.Item label="Väljastamise kuupäev" name="removedAt">
                <DatePicker/>
            </Form.Item>
        </Form>
    }

    return (
        <>
            <Button type={"link"} onClick={showModal} danger>
                Väljasta
            </Button>
            <Modal
                title="Väljasta alus või osa kaupa"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                afterClose={handleModalDestroy}
                okText={"Väljasta"}
                cancelText={"Tühista"}
                okType={"danger"}
                confirmLoading={confirmLoading}
                destroyOnClose={true}
            >
                <ModalForm/>
            </Modal>
        </>
    );
}

export default DeletePalletModal

