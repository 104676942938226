import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./types";
import { BatchActionType } from "../../Models/Batch";
import apiClient from "../api";
import { fetchBatches, fetchPallets } from "./actions";
import mappers from "./mappers";
import ActionType from "../../Models/ActionType";
import { PalletActionType } from "../../Models/Pallet";
import qs from "qs";
function* handleCreateBatch(action: BatchActionType) {
  console.log("Handle create batch", action.batch);
  try {
    const { data } = yield call(
      apiClient.post,
      "batches/",
      action.batch && mappers.batch.mapBatchDTO(action.batch)
    );
    yield put({ type: types.CREATE_BATCH_SUCCESS, data });
    yield put({ type: types.CLOSE_CREATE_BATCH_MODAL });
    yield put(fetchBatches());
  } catch (e: any) {
    yield put({ type: types.CREATE_BATCH_FAILURE });
    console.log(e);
  }
}

function* handleFetchBatches(action: BatchActionType) {
  try {
    const { data } = yield call(apiClient.get, "batches/");
    const batches = data.map((d: any) => mappers.batch.mapToBatch(d));
    yield put({ type: types.FETCH_BATCHES_SUCCESS, payload: batches });
  } catch (e: any) {
    yield put({ type: types.FETCH_BATCHES_FAILURE });
    console.log(e);
  }
}

function* handleFetchFilteredBatches(action: BatchActionType) {
  try {
    const query = qs.stringify(action.filter, { encodeValuesOnly: true });
    const { data } = yield call(apiClient.get, `batches?${query}`);
    const batches = data.map((d: any) => mappers.batch.mapToBatch(d));
    yield put({
      type: types.FETCH_FILTERED_BATCHES_SUCCESS,
      payload: batches,
      index: action.index,
    });
  } catch (e: any) {
    yield put({ type: types.FETCH_FILTERED_BATCHES_FAILURE });
    console.log(e);
  }
}

function* handleFetchProducers(action: ActionType) {
  try {
    const { data } = yield call(apiClient.get, "producers/");
    const producers = data.map((d: any) => mappers.producer.mapToProducer(d));
    yield put({ type: types.FETCH_PRODUCERS_SUCCESS, payload: producers });
  } catch (e: any) {
    yield put({ type: types.FETCH_PRODUCERS_FAILURE });
    console.log(e);
  }
}

function* handleFetchProducts(action: ActionType) {
  try {
    const { data } = yield call(apiClient.get, "products/");
    const products = data.map((d: any) => mappers.product.mapToProduct(d));
    yield put({ type: types.FETCH_PRODUCTS_SUCCESS, payload: products });
  } catch (e: any) {
    yield put({ type: types.FETCH_PRODUCTS_FAILURE });
    console.log(e);
  }
}

function* handleFetchFilteredProducts(action: ActionType) {
  try {
    const query = qs.stringify(action.filter, { encodeValuesOnly: true });
    const { data } = yield call(apiClient.get, `products?${query}`);
    const products = data.map((d: any) => mappers.product.mapToProduct(d));
    yield put({
      type: types.FETCH_FILTERED_PRODUCTS_SUCCESS,
      payload: products,
      index: action.index,
    });
  } catch (e: any) {
    yield put({ type: types.FETCH_FILTERED_PRODUCTS_FAILURE });
    console.log(e);
  }
}

function* handleFetchPallets(action: ActionType) {
  try {
    const { data } = yield call(apiClient.get, "pallets/");
    const pallets = data.map((d: any) => mappers.pallet.mapToPallet(d));
    yield put({ type: types.FETCH_PALLETS_SUCCESS, payload: pallets });
  } catch (e: any) {
    yield put({ type: types.FETCH_PALLETS_FAILURE });
    console.log(e);
  }
}

function* handleCreatePallet(action: PalletActionType) {
  try {
    const { data } = yield call(
      apiClient.post,
      "pallets/",
      action.pallet && mappers.pallet.mapPalletDTO(action.pallet)
    );
    yield put({ type: types.CREATE_PALLET_SUCCESS, data });
    yield put({ type: types.CLOSE_CREATE_PALLET_MODAL });
    yield put(fetchPallets());
  } catch (e: any) {
    yield put({ type: types.CREATE_PALLET_FAILURE });
    console.log(e);
  }
}

const translatorSagas = [
  takeLatest(types.CREATE_BATCH, handleCreateBatch),
  takeLatest(types.FETCH_BATCHES, handleFetchBatches),
  takeLatest(types.FETCH_FILTERED_BATCHES, handleFetchFilteredBatches),
  takeLatest(types.FETCH_PRODUCERS, handleFetchProducers),
  takeLatest(types.FETCH_PRODUCTS, handleFetchProducts),
  takeLatest(types.FETCH_FILTERED_PRODUCTS, handleFetchFilteredProducts),
  takeLatest(types.FETCH_PALLETS, handleFetchPallets),
  takeLatest(types.CREATE_PALLET, handleCreatePallet),
];

export default translatorSagas;
