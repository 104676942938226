import { UploadFile } from "antd/lib/upload/interface";
import ActionType from "./ActionType";

export interface DocumentFile {
  filename: string;
  file: UploadFile;
  type: string;
}

export enum DocumentFilePath {
  batches = "batches",
}

export enum DocumentFileRef {
  batches = "application::batch.batch",
}
export interface DocumentFileInfo {
  path: DocumentFilePath; // File upload dir
  refId: string; //Entry id which it will be linked to
  ref: DocumentFileRef; // Entry model id
  field: string; // Entry field the file will be linked to
}

export interface DocumentFileActionType extends ActionType {
  file: DocumentFile;
  fileInfo: DocumentFileInfo;
}

/*path (optional)	The folder where the file(s) will be uploaded to (only supported on strapi-provider-upload-aws-s3).
refId	The ID of the entry which the file(s) will be linked to.
ref	The unique ID (uid) of the model which the file(s) will be linked to (see more below).
source (optional)	The name of the plugin where the model is located.
field*/
