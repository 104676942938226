import * as types from "./types";
import { BatchActionType, BatchDTO } from "../../Models/Batch";
import ActionType from "../../Models/ActionType";
import { PalletActionType, PalletDTO } from "../../Models/Pallet";

export const createBatch: (batch: BatchDTO) => BatchActionType = (batch) => ({
  type: types.CREATE_BATCH,
  batch,
});

export const openCreateBatchModal: () => BatchActionType = () => ({
  type: types.OPEN_CREATE_BATCH_MODAL,
});

export const closeCreateBatchModal: () => BatchActionType = () => ({
  type: types.CLOSE_CREATE_BATCH_MODAL,
});

export const fetchBatches: () => BatchActionType = () => ({
  type: types.FETCH_BATCHES,
});

export const fetchFilteredBatches: (
  filter: object,
  index: number
) => ActionType = (filter, index) => ({
  type: types.FETCH_FILTERED_BATCHES,
  filter,
  index,
});

export const fetchProducts: () => ActionType = () => ({
  type: types.FETCH_PRODUCTS,
});

export const fetchFilteredProducts: (
  filter: object,
  index: number
) => ActionType = (filter, index) => ({
  type: types.FETCH_FILTERED_PRODUCTS,
  filter,
  index,
});

export const fetchProducers: () => ActionType = () => ({
  type: types.FETCH_PRODUCERS,
});

export const createPallet: (pallet: PalletDTO) => PalletActionType = (
  pallet
) => ({
  type: types.CREATE_PALLET,
  pallet,
});

export const openCreatePalletModal: () => ActionType = () => ({
  type: types.OPEN_CREATE_PALLET_MODAL,
});

export const closeCreatePalletModal: () => ActionType = () => ({
  type: types.CLOSE_CREATE_PALLET_MODAL,
});

export const fetchPallets: () => ActionType = () => ({
  type: types.FETCH_PALLETS,
});
