import { Pallet, PalletDeleteDTO, PalletDTO } from "../Models/Pallet";
import { Warehouse } from "../Models/Warehouse";
import { PalletItem } from "../Models/PalletItem";
import RestService from "./RestService";
import Config from "../Config/Config";
import { store } from "../App";

const Post = (
  palletDTO: PalletDTO,
  callback: () => void,
  jwt: string | null | undefined
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(mapDTO(palletDTO)),
  };

  fetch(Config.baseURL + "/pallets", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      callback();
      return response;
    })
    .catch((error) => {
      alert(error);
      console.log(error);
    });
};

const Delete = (
  dto: PalletDeleteDTO,
  callback: () => void,
  jwt: string | null | undefined
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(mapDeleteDTO(dto)),
  };

  fetch(`${Config.baseURL}/pallets/delete/${dto.id}`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      callback();
      return response;
    })
    .catch((error) => {
      alert(error);
      console.log(error);
    });
};

function mapDTO(palletDTO: PalletDTO): object {
  return {
    warehouse: palletDTO.warehouseId,
    number_of_pallets: palletDTO.numberOfPallets,
    stored_at: palletDTO.storedAt,
    type: palletDTO.type,
    pallet_items: palletDTO.palletItems.map((p) => {
      return {
        product_id: p.productId,
        quantity: p.quantity,
      };
    }),
  };
}

function mapDeleteDTO(dto: PalletDeleteDTO): object {
  return {
    removed_at: dto.removedAt,
  };
}

function mapResponse(payload: any): Pallet[] {
  return payload.map((p: any) => {
    return mapPallet(p);
  });
}

function mapPallet(p: any): Pallet {
  return {
    type: p.type,
    palletItems: mapProducts(p.pallet_items),
    id: p.id,
    warehouse: p.warehouse,
    storedAt: p.stored_at,
    removedAt: p.removed_at,
  };
}

function mapProducts(products: any): PalletItem[] {
  return products.map((p: any) => {
    return mapPalletItem(p);
  });
}

function mapPalletItem(p: any): PalletItem {
  return {
    id: p.id,
    product: p.product,
    quantity: p.quantity,
    events: [],
    batch: p.batch,
  };
}

const Get = (
  warehouse: Warehouse | undefined,
  revision: number,
  showArchived: boolean
) => {
  let filter = showArchived
    ? "?removed_at_null=false"
    : "?removed_at_null=true";
  if (warehouse !== undefined) {
    filter += "&warehouse=" + warehouse.id;
  }

  return RestService.get<Pallet>({
    endpoint: "pallets",
    revision,
    filter,
    mapper: mapResponse,
  });
};

const PalletService = () => {
  const state = store.getState();
  const jwt = state.auth.authorizationToken;
  return {
    get: Get,
    post: (palletDTO: PalletDTO, callback: () => void) =>
      Post(palletDTO, callback, jwt),
    delete: (dto: PalletDeleteDTO, callback: () => void) =>
      Delete(dto, callback, jwt),
  };
};

export const PalletUtils = {
  mapPallet,
  mapPalletItem,
};

export default PalletService;
