import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Table,
} from "antd";
import { Label } from "../../Models/Label";
import LabelHistoryService from "../../Services/LabelHistoryService";
import { LabelHistory, LabelHistoryDTO } from "../../Models/LabelHistory";
import { PrinterFilled } from "@ant-design/icons/lib";
import PrinterService from "../../Services/PrinterService";
import { ServiceStatus } from "../../Models/Service";
import Title from "antd/lib/typography/Title";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment, { Moment } from "moment";
import { LabelType } from "../../Models/LabelType";

const templates = {
  "Figuraata värviline 150x100mm": require("../LabelTemplates/Figuraata värviline 150x100mm"),
  "Figuraata värviline 85x100mm": require("../LabelTemplates/Figuraata värviline 85x100mm"),
  "Figuraata värviline konteiner 85x100mm (eng)": require("../LabelTemplates/Figuraata värviline konteiner 85x100mm (eng)"),
  "Figuraata värviline 85x100mm (eng)": require("../LabelTemplates/Figuraata värviline 85x100mm (eng)"),
  "Figuraata värviline 150x100mm (ee,en)": require("../LabelTemplates/Figuraata värviline 150x100mm (ee,en)"),
  "Figuraata värviline 85x100mm (ee,en)": require("../LabelTemplates/Figuraata värviline 85x100mm (ee,en)"),
};

interface AddLabelModalProps {
  onPrint: () => void;
  revision: number;
  label: Label;
}

interface RowType extends LabelHistory {
  counter: number;
}

interface FormState {
  quantity: number;
  lot: string;
  origin: string;
  bestBefore?: Moment;
  unitSize: number;
  changed: boolean;
  weight: number;
}

function PrintLabelModal(props: AddLabelModalProps) {
  const [visible, setVisible] = useState(false);
  const [displayWholeHistory, setDisplayWholeHistory] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [wasAdded, setWasAdded] = useState(false);
  const [showBoxSelection, setShowBoxSelection] = useState(false);
  const [initialValues, setInitialValues] = useState({
    quantity: 1,
    lot: "",
    origin: "Eesti",
    bestBefore: undefined,
    unitSize: 8,
    changed: false,
    weight: props.label.weight / 1000,
  } as FormState);
  const [form] = Form.useForm();
  const labelHistoryService = LabelHistoryService();
  const labelHistoryGetService = LabelHistoryService().get(
    props.label.id,
    props.revision,
    visible
  );

  useEffect(() => {
    if (labelHistoryGetService.status === ServiceStatus.Loaded) {
      if (initialValues.changed) {
        return;
      }
      const histories = labelHistoryGetService.payload;
      const latest = histories.sort((a, b) => {
        return a.id - b.id;
      })[histories.length - 1];

      form.setFieldsValue({
        quantity: latest ? latest.quantity : 1,
        lot: latest ? latest.lot : "",
        origin: latest ? latest.origin : "Eesti",
        bestBefore: latest
          ? moment.utc(latest.bestBefore, "YYYY-MM-DD")
          : undefined,
        unitSize: 8,
        changed: false,
        weight: props.label.weight / 1000,
      });
    }
  }, [labelHistoryGetService, visible]);

  const showModal = () => {
    setShowBoxSelection(false);
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleLabelCreated = () => {
    setWasAdded(true);
    setVisible(false);
    setConfirmLoading(false);
  };

  function handleFinish(values: any) {
    const dto: LabelHistoryDTO = {
      bestBefore: values.bestBefore,
      labelId: props.label.id,
      origin: values.origin,
      quantity: values.quantity,
      lot: values.lot,
      isBoxLabel: showBoxSelection,
      unitSize: values.unitSize,
      weight: values.weight * 1000 || props.label.weight,
    };
    console.log("HANDLE FINISH", values, dto);
    setConfirmLoading(true);

    // @ts-ignore
    let templateFunction = templates[props.label.type.title].build;
    if (showBoxSelection) {
      templateFunction = templates["Figuraata värviline 85x100mm"].build;
    }
    if (
      showBoxSelection &&
      props.label.type.title === "Figuraata värviline 150x100mm (ee,en)"
    ) {
      templateFunction =
        templates["Figuraata värviline 85x100mm (ee,en)"].build;
    }

    const l = {
      ...props.label,
      flippedHorizontally: showBoxSelection
        ? false
        : props.label.flippedHorizontally,
    };

    const label = templateFunction(l, dto);
    PrinterService.printLabel(label).then((r) =>
      labelHistoryService.post(dto, handleLabelCreated)
    );
    console.log(label);
    // @ts-ignore
    copy(label);
    //handleLabelCreated()
  }

  function copy(text: string) {
    var input = document.createElement("textarea");
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
  }

  const handleModalDestroy = () => {
    if (wasAdded) {
      props.onPrint();
    }
  };

  const getDefaultValue = (
    arr: any[],
    selectedValue?: any
  ): number | undefined => {
    if (selectedValue !== undefined) {
      return selectedValue.id;
    }
    if (arr.length > 0) {
      return arr[0].id;
    }
    return undefined;
  };

  const HistoryTable = () => {
    const columns = [
      {
        title: "Printimise kp",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => (
          <strong>{moment(createdAt).format("DD.MM.YYYY")}</strong>
        ),
      },
      {
        title: "Lot",
        dataIndex: "lot",
        key: "lot",
        render: (lot: string) => <strong>{lot}</strong>,
      },
      {
        title: "Parim enne",
        dataIndex: "bestBefore",
        key: "bestBefore",
        render: (bestBefore: string) => (
          <strong>{moment(bestBefore).format("DD.MM.YYYY")}</strong>
        ),
      },
      {
        title: "Päritolumaa",
        dataIndex: "origin",
        key: "origin",
        render: (origin: string) => <strong>{origin}</strong>,
      },
      {
        title: "Kogus",
        dataIndex: "quantity",
        key: "quantity",
        render: (quantity: string) => <strong>{quantity}</strong>,
      },
    ];
    var data: RowType[] = [];
    var dataSize = 0;
    if (labelHistoryGetService.status === ServiceStatus.Loaded) {
      data = labelHistoryGetService.payload
        .sort((a, b) => {
          return (
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
          );
        })
        .map((h, i) => {
          return {
            ...h,
            counter: i,
          };
        });
      dataSize = data.length;
    }

    if (!displayWholeHistory) {
      data = data.slice(0, 5);
    }

    return (
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        pagination={false}
        footer={() =>
          !displayWholeHistory &&
          dataSize > 5 && (
            <div style={{ textAlign: "center" }}>
              <Button
                type={"link"}
                onClick={() => setDisplayWholeHistory(true)}
              >
                Näita kogu ajalugu
              </Button>
            </div>
          )
        }
      />
    );
  };

  const onIsBoxLabelChange = (e: CheckboxChangeEvent) => {
    console.log("checked = ", e.target.checked);
    setShowBoxSelection(e.target.checked);
  };

  const ModalForm = () => {
    if (labelHistoryGetService.status === ServiceStatus.Loaded) {
      return (
        <div>
          <Form
            form={form}
            preserve={true}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            initialValues={initialValues}
            onFinish={handleFinish}
          >
            <Form.Item
              label="Lot"
              name="lot"
              rules={[{ required: true, message: "Kohustuslik väli" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Parim enne"
              name="bestBefore"
              rules={[{ required: true, message: "Kohustuslik väli" }]}
            >
              <DatePicker placeholder={"Parim enne"} format={"DD.MM.YYYY"} />
            </Form.Item>
            <Form.Item
              label="Etikettide arv"
              name="quantity"
              rules={[{ required: true, message: "Kohustuslik väli" }]}
            >
              <InputNumber />
            </Form.Item>

            {props.label.barcode === "-1" && (
              <Form.Item
                label="Kaal (kg)"
                name="weight"
                rules={[{ required: true, message: "Kohustuslik väli" }]}
              >
                <InputNumber />
              </Form.Item>
            )}

            <Form.Item
              label="Päritolu"
              name="origin"
              rules={[{ required: true, message: "Kohustuslik väli" }]}
            >
              <Select style={{ width: "100%" }}>
                <Select.Option value={"Costa Rica"}>Costa Rica</Select.Option>
                <Select.Option value={"Eesti"}>Eesti</Select.Option>
                <Select.Option value={"Eesti, Läti, Ukraina"}>
                  Eesti, Läti, Ukraina
                </Select.Option>
                <Select.Option value={"Eesti, Ukraina"}>
                  Eesti, Ukraina
                </Select.Option>
                <Select.Option value={"Belgia"}>Belgia</Select.Option>
                <Select.Option value={"Egiptus"}>Egiptus</Select.Option>
                <Select.Option value={"Guatemala"}>Guatemala</Select.Option>
                <Select.Option value={"Hiina"}>Hiina</Select.Option>
                <Select.Option value={"Hispaania"}>Hispaania</Select.Option>
                <Select.Option value={"India"}>India</Select.Option>
                <Select.Option value={"Itaalia"}>Itaalia</Select.Option>
                <Select.Option value={"Kreeka"}>Kreeka</Select.Option>
                <Select.Option value={"Läti"}>Läti</Select.Option>
                <Select.Option value={"Läti, Ukraina"}>
                  Läti, Ukraina
                </Select.Option>
                <Select.Option value={"Leedu"}>Leedu</Select.Option>
                <Select.Option value={"Makedoonia"}>Makedoonia</Select.Option>
                <Select.Option value={"Maroko"}>Maroko</Select.Option>
                <Select.Option value={"Peruu"}>Peruu</Select.Option>
                <Select.Option value={"Poola"}>Poola</Select.Option>
                <Select.Option value={"Rootsi"}>Rootsi</Select.Option>
                <Select.Option value={"Serbia"}>Serbia</Select.Option>
                <Select.Option value={"Soome"}>Soome</Select.Option>
                <Select.Option value={"Tšiili"}>Tšiili</Select.Option>
                <Select.Option value={"Türgi"}>Türgi</Select.Option>
                <Select.Option value={"Ukraina"}>Ukraina</Select.Option>
                <Select.Option value={"Venemaa"}>Venemaa</Select.Option>
                <Select.Option value={"Vietnam"}>Vietnam</Select.Option>
                <Select.Option value={"Vietnam, Costa Rica, Egiptus"}>
                  Vietnam, Costa Rica, Egiptus
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Prindi kastisilt"
              name="isBoxLabel"
              valuePropName="checked"
            >
              <Checkbox onChange={onIsBoxLabelChange} />
            </Form.Item>
            {showBoxSelection && (
              <Form.Item
                label="Kogus kastis"
                name="unitSize"
                rules={[{ required: true, message: "Kohustuslik väli" }]}
              >
                <Radio.Group>
                  <Radio.Button value={3}>3 kg</Radio.Button>
                  <Radio.Button value={5}>5 kg</Radio.Button>
                  <Radio.Button value={6}>6 kg</Radio.Button>
                  <Radio.Button value={7}>7 kg</Radio.Button>
                  <Radio.Button value={7.5}>7.5 kg</Radio.Button>
                  <Radio.Button value={8}>8 kg</Radio.Button>
                  <Radio.Button value={9}>9 kg</Radio.Button>
                  <Radio.Button value={10}>10 kg</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
          </Form>

          <Title level={3}>Ajalugu</Title>
          <HistoryTable />
        </div>
      );
    }
    return <div>Loading ...</div>;
  };

  return (
    <>
      <a onClick={showModal}>
        <PrinterFilled />
      </a>
      <Modal
        width={800}
        title={"Prindi etikett: " + props.label.title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleModalDestroy}
        okText={"Prindi"}
        cancelText={"Tühista"}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <ModalForm />
      </Modal>
    </>
  );
}

export default PrintLabelModal;
