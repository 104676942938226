import React, { useState } from "react";
import { Product } from "../../Models/Product";
import { Row, Space } from "antd";
import AddProductModal from "../../Components/ProductsTable/AddProductModal";
import ProductsTable from "../../Components/ProductsTable/ProductsTable";
import ProducersTable from "../../Components/ProducersTable/ProducersTable";

interface ProducersViewProps {}

const ProducersView = (props: ProducersViewProps) => {
  let [revision, setRevision] = useState(1);

  const nextRevision = () => {
    setRevision(revision + 1);
  };
  const ProductsTableHeader = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Space>
          <AddProductModal revision={revision} onAdd={() => nextRevision()} />
        </Space>
      </Row>
    );
  };

  return (
    <>
      <ProductsTableHeader />
      <ProducersTable
        onChange={() => {
          nextRevision();
        }}
        revision={revision}
      />
    </>
  );
};

export default ProducersView;
