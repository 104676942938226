import { Batch } from "../../Models/Batch";
import ActionType from "../../Models/ActionType";
import * as types from "./types";
import { Product } from "../../Models/Product";
import { Producer } from "../../Models/Producer";
import { Pallet } from "../../Models/Pallet";

interface BatchState {
  batches: Batch[];
  filteredBatches: { [key: number]: Batch[] };
  isCreateBatchModalOpen: boolean;
  creatingBatch: boolean;
  fetchingBatches: boolean;
  fetchingFilteredBatches: boolean;
}

interface ProductState {
  products: Product[];
  filteredProducts: { [key: number]: Product[] };
  fetchingProducts: boolean;
  fetchingFilteredProducts: boolean;
}

interface ProducerState {
  producers: Producer[];
  fetchingProducers: boolean;
}

export interface PalletState {
  pallets: Pallet[];
  createPalletModalOpen: boolean;
  creatingPallet: boolean;
  fetchingPallets: boolean;
}
export interface WarehouseState {
  batch: BatchState;
  product: ProductState;
  producer: ProducerState;
  pallet: PalletState;
}

const initialState = {
  batch: {
    batches: [],
    filteredBatches: {},
    isCreateBatchModalOpen: false,
    creatingBatch: false,
    fetchingBatches: false,
    fetchingFilteredBatches: false,
  },
  product: {
    products: [],
    filteredProducts: {},
    fetchingProducts: false,
    fetchingFilteredProducts: false,
  },
  producer: {
    producers: [],
    fetchingProducers: false,
  },
  pallet: {
    pallets: [],
    createPalletModalOpen: false,
    creatingPallet: false,
    fetchingPallets: false,
  },
};

const warehouse = (
  state: WarehouseState = initialState,
  action: ActionType
): WarehouseState => {
  switch (action.type) {
    case types.CREATE_BATCH:
      return {
        ...state,
        batch: {
          ...state.batch,
          creatingBatch: true,
        },
      };
    case types.CREATE_BATCH_SUCCESS:
      return {
        ...state,
        batch: {
          ...state.batch,
          creatingBatch: false,
        },
      };
    case types.CREATE_BATCH_FAILURE:
      return {
        ...state,
        batch: {
          ...state.batch,
          creatingBatch: false,
        },
      };

    case types.OPEN_CREATE_BATCH_MODAL:
      return {
        ...state,
        batch: {
          ...state.batch,
          isCreateBatchModalOpen: true,
        },
      };
    case types.CLOSE_CREATE_BATCH_MODAL:
      return {
        ...state,
        batch: {
          ...state.batch,
          isCreateBatchModalOpen: false,
        },
      };

    case types.FETCH_BATCHES:
      return {
        ...state,
        batch: {
          ...state.batch,
          fetchingBatches: true,
        },
      };
    case types.FETCH_BATCHES_SUCCESS:
      return {
        ...state,
        batch: {
          ...state.batch,
          fetchingBatches: false,
          batches: action.payload as Batch[],
        },
      };
    case types.FETCH_BATCHES_FAILURE:
      return {
        ...state,
        batch: {
          ...state.batch,
          fetchingBatches: false,
        },
      };
    case types.FETCH_FILTERED_BATCHES:
      return {
        ...state,
        batch: {
          ...state.batch,
          filteredBatches: [],
          fetchingFilteredBatches: true,
        },
      };
    case types.FETCH_FILTERED_BATCHES_SUCCESS: {
      let newState = {
        ...state,
        batch: {
          ...state.batch,
          fetchingFilteredBatches: false,
        },
      };
      if (action.index !== undefined && action.index >= 0) {
        newState.batch.filteredBatches[action.index] =
          action.payload as Batch[];
      }
      return newState;
    }
    case types.FETCH_FILTERED_BATCHES_FAILURE:
      return {
        ...state,
        batch: {
          ...state.batch,
          fetchingFilteredBatches: false,
        },
      };
    case types.FETCH_PRODUCERS:
      return {
        ...state,
        producer: {
          ...state.producer,
          fetchingProducers: true,
        },
      };
    case types.FETCH_PRODUCERS_SUCCESS:
      return {
        ...state,
        producer: {
          ...state.producer,
          fetchingProducers: false,
          producers: action.payload as Producer[],
        },
      };
    case types.FETCH_PRODUCERS_FAILURE:
      return {
        ...state,
        producer: {
          ...state.producer,
          fetchingProducers: false,
        },
      };
    case types.FETCH_PRODUCTS:
      return {
        ...state,
        product: {
          ...state.product,
          fetchingProducts: true,
        },
      };
    case types.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          fetchingProducts: false,
          products: action.payload as Product[],
        },
      };
    case types.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          fetchingProducts: false,
        },
      };
    case types.FETCH_FILTERED_PRODUCTS:
      return {
        ...state,
        product: {
          ...state.product,
          fetchingFilteredProducts: true,
        },
      };
    case types.FETCH_FILTERED_PRODUCTS_SUCCESS: {
      let newState = {
        ...state,
        product: {
          ...state.product,
          fetchingFilteredProducts: false,
        },
      };
      if (action.index !== undefined && action.index >= 0) {
        newState.product.filteredProducts[action.index] =
          action.payload as Product[];
      }
      return newState;
    }

    case types.FETCH_FILTERED_PRODUCTS_FAILURE:
      return {
        ...state,
        product: {
          ...state.product,
          fetchingFilteredProducts: false,
        },
      };
    case types.CREATE_PALLET:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          creatingPallet: true,
        },
      };
    case types.CREATE_PALLET_SUCCESS:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          creatingPallet: false,
        },
      };
    case types.CREATE_PALLET_FAILURE:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          creatingPallet: false,
        },
      };
    case types.FETCH_PALLETS:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          fetchingPallets: true,
        },
      };
    case types.FETCH_PALLETS_SUCCESS:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          fetchingPallets: false,
          pallets: action.payload as Pallet[],
        },
      };
    case types.FETCH_PALLETS_FAILURE:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          fetchingPallets: false,
        },
      };
    case types.OPEN_CREATE_PALLET_MODAL:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          createPalletModalOpen: true,
        },
        product: {
          ...state.product,
          filteredProducts: {},
        },
        batch: {
          ...state.batch,
          filteredBatches: {},
        },
      };
    case types.CLOSE_CREATE_PALLET_MODAL:
      return {
        ...state,
        pallet: {
          ...state.pallet,
          createPalletModalOpen: false,
        },
      };
    default:
      return state;
  }
};

export default warehouse;
