import * as types from "./types";
import { UserCredentials } from "../../Models/Auth";

export const authenticateUser = (userCredentials: UserCredentials) => ({
  type: types.AUTHENTICATE_USER,
  userCredentials,
});

export const logout = () => ({
  type: types.LOGOUT,
});
