import React, { useState } from "react";
import { TelemaOrder } from "../../Models/TelemaOrder";
import { Button, Table, TablePaginationConfig } from "antd";
import { ColumnProps } from "antd/lib/table";
import { TelemaPartner } from "../../Models/TelemaPartner";
import { json2xml } from "xml-js";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import {
  TelemaBulkOrder,
  TelemaBulkOrderDTO,
} from "../../Models/TelemaBulkOrder";
import { Link } from "react-router-dom";

const _ = require("lodash");

interface Props {
  orders: TelemaOrder[];
  createBulkOrder: (bulkOrder: TelemaBulkOrderDTO) => void;
  importOrdersFromTelema: () => void;
}

interface RowType extends TelemaOrder {
  counter: number;
}

function TelemaOrdersTable(props: Props) {
  let data: RowType[] = props.orders.map((o, idx) => {
    return {
      ...o,
      counter: idx + 1,
    };
  });

  const [deliveryDateFilters, setDeliveryDateFilters] = useState({});

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    rows: any,
    info: any
  ) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys, info);
    if (info.type === "single") {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const onSelectAll = (
    selected: boolean,
    selectedRows: RowType[],
    changeRows: RowType[]
  ) => {
    if (!selected) {
      console.log("HELLO");
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(selectedRows.map((row) => row.id));
    }
    console.log("ON SELECT ALL", selected);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: onSelectAll,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns: ColumnProps<RowType>[] = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => <strong>{index + 1}</strong>,
    },
    {
      title: "Tellimuse number",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (orderNumber: string) => <strong>{orderNumber}</strong>,
    },
    {
      title: "Ostja",
      dataIndex: "buyer",
      key: "buyer",
      render: (buyer: TelemaPartner) => <strong>{buyer.name}</strong>,
    },
    {
      title: "Tellija",
      dataIndex: "orderParty",
      key: "orderParty",
      render: (orderParty: TelemaPartner) => <strong>{orderParty.name}</strong>,
    },
    {
      title: "Tellimus",
      dataIndex: "orderParty",
      key: "orderParty",
      render: (orderParty: TelemaPartner, row) => (
        <strong>
          {row.products.map((p) => (
            <div key={p.id}>
              {p.quantity / 8} x box {p.sellerGTIN} {p.productName}
            </div>
          ))}
        </strong>
      ),
    },
    {
      title: "Tellimuse kuupäev",
      dataIndex: "date",
      key: "date",
      render: (date) => <strong>{date}</strong>,
    },
    {
      title: "Tarne kuupäev",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (deliveryDate) => <strong>{deliveryDate}</strong>,
      //@ts-ignore
      filters: [...new Set(data.map((d) => d.deliveryDate))].map((a) => {
        return {
          text: a,
          value: a,
        };
      }),
      onFilter: (value: any, record) => record.deliveryDate === value,
    },
    {
      title: "Viited",
      dataIndex: "bulkOrder",
      key: "bulkOrder",
      render: (bulkOrder: TelemaBulkOrder | undefined) => (
        <span>
          {bulkOrder && "KoondTellimus:"} {bulkOrder?.orderNumber || ""}
        </span>
      ),
    },
  ];

  const toMainOrder = () => {
    let orders = data.filter((d) =>
      rowSelection.selectedRowKeys.includes(d.id)
    );
    if (orders.length === 0) {
      alert("Ühtegi tellimust pole valitud");
      return;
    }

    const deliveryDates = new Set(orders.map((o) => o.deliveryDate));
    if (deliveryDates.size > 1) {
      alert("Tellimused on valitud erinevate tarne kuupäevadega");
      return;
    }

    const bulkOrders = orders.filter((o) => o.bulkOrder !== undefined);

    /*if (bulkOrders.length > 0) {
      alert("Mõni valitud tellimus on juba koondtellimuses");
      return;
    }*/

    const today = new Date().toISOString().split("T")[0];

    props.createBulkOrder({
      orderDate: today,
      issueDate: today,
      deliveryDate: orders[0].deliveryDate,
      orders: orders,
    });
    return;

    /*orders.forEach((order) => {
      orderNumbers.push(order.orderNumber);
      order.products.forEach((p) => {
        if (products[p.sellerGTIN]) {
          products[p.sellerGTIN].quantity += p.quantity;
          products[p.sellerGTIN].boxes += p.quantity / 8;
        } else {
          products[p.sellerGTIN] = {
            ...p,
            boxes: p.quantity / 8,
          };
        }
      });
    });
    console.log(products);
    console.log(orderNumbers.join(", "));
    toXML(products, orders);*/
  };

  const toXML = (totalProducts: any, orders: TelemaOrder[]) => {
    const today = new Date().toISOString().split("T")[0];

    const productsJson = Object.values(totalProducts).map(
      (productTotal: any, index) => {
        return {
          LineItemNum: index + 1,
          SellerItemCode: productTotal.sellerGTIN,
          ItemDescription: productTotal.productName,
          GTIN: productTotal.sellerGTIN,
          ItemUnitRecord: {
            ItemUnit: "KAST",
          },
          BaseUnit: "KAST",
          AmountOrdered: productTotal.boxes,
        };
      }
    );

    const sourceDocumentsJson = Object.values(orders).map((order) => {
      return {
        _attributes: {
          type: "CUSTOMER-ORDER",
        },
        SourceDocumentNum: `${order.orderParty.name}-${order.orderNumber}`,
        SourceDocumentDate: order.date,
      };
    });

    const jsonData = {
      "E-Document": {
        Header: {
          DateIssued: today,
          SenderID: "10389466",
          SenderRegNum: "10389466",
          ReceiverID: "12058696",
          ReceiverRegNum: "12058696",
        },
        Document: {
          DocumentType: "desorder",
          DocumentParties: {
            SellerParty: {
              _attributes: {
                context: "self",
              },
              PartyCode: "FIGURAATA",
              Name: "Figuraata OÜ",
              RegNum: "10389466",
              VATRegNum: "EE100043349",
              ContactData: {
                ActualAddress: {
                  Address1: "Jõe 11b",
                  City: "Räpina",
                  PostalCode: "64505",
                  County: "Põlvamaa",
                  CountryCode: "EE",
                },
              },
            },
            BuyerParty: {
              _attributes: {
                context: "partner",
              },
              PartyCode: "SELVER",
              Name: "Selver AS",
              RegNum: "10379733",
              VATRegNum: "EE100247019",
              ContactData: {
                ActualAddress: {
                  Address1: "Pärnu mnt 238",
                  City: "Tallinn",
                  PostalCode: "11624",
                  County: "Harjumaa",
                  CountryCode: "EE",
                },
              },
            },
            DeliveryParty: {
              _attributes: {
                context: "partner",
              },
              PartyCode: "4740581999374",
              Name: "AS Balbiino - Selveri jaotuskeskus",
              RegNum: "10009159",
              ContactData: {
                ActualAddress: {
                  Address1: "Viljandi mnt 26",
                  City: "Tallinn",
                  PostalCode: "11217",
                  County: "Harjumaa",
                  CountryCode: "EE",
                },
              },
            },
          },
          DocumentInfo: {
            DocumentName: "koondtellimus",
            DocumentNum: formatOrderNumberByDate(today, 1),
            DateInfo: {
              OrderDate: today,
              IssueDate: today,
              DeliveryDateRequested: orders[0].deliveryDate,
            },
            RefInfo: {
              SourceDocument: sourceDocumentsJson,
            },
          },
          DocumentItem: {
            ItemEntry: productsJson,
          },
        },
      },
    };
    //@ts-ignore
    const xmlData = json2xml(jsonData, {
      compact: true,
      ignoreComment: false,
      spaces: 2,
    });
    const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>\n' + xmlData;
    /*fs.writeFileSync(
      `${formatOrderNumberByDate(today, 1)}.xml`,
      xmlHeader,
      "utf-8"
    );*/
    const blob = new Blob([xmlHeader], { type: "text/xml" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `O${formatOrderNumberByDate(today, 1)}.xml`; // Set the desired filename
    downloadLink.click();

    // Clean up the URL object
    URL.revokeObjectURL(downloadLink.href);
    console.log(xmlHeader);
  };

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => {
    console.log(filters);
    if (filters.deliveryDate !== deliveryDateFilters) {
      setSelectedRowKeys([]);
    }
  };

  const importOrdersFromTelema = () => {
    props.importOrdersFromTelema();
  };

  return (
    <>
      <Button onClick={importOrdersFromTelema}>
        impordi tellimused telemast
      </Button>
      <Button onClick={toMainOrder}>
        Koosta koondtellimus ({selectedRowKeys.length})
      </Button>
      <Link to={"statistics"}>Statistika</Link>
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        pagination={false}
        rowSelection={rowSelection}
        onChange={onTableChange}
      />
    </>
  );
}

const formatOrderNumberByDate = (dateString: string, number: number) => {
  // Convert date string to a JavaScript Date object
  const dateObject = new Date(dateString);

  // Extract year, month, and day components
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(dateObject.getDate()).padStart(2, "0");

  // Format the components into the desired string format "YYYYMMDD"
  const formattedDate = `${year}${month}${day}${number}`;
  return formattedDate;
};
export default TelemaOrdersTable;
