import { Batch, BatchDocument, BatchDTO } from "../../Models/Batch";
import { Pallet, PalletDTO } from "../../Models/Pallet";
import { Producer } from "../../Models/Producer";
import { Product } from "../../Models/Product";
import {
  PalletItem,
  PalletItemDTO,
  PalletItemEvent,
} from "../../Models/PalletItem";

const mapBatchDTO = (batchDTO: BatchDTO): object => ({
  lot: batchDTO.lot,
  producer: batchDTO.producerId,
  product: batchDTO.productId,
  quantity: batchDTO.quantity,
  best_before: batchDTO.bestBefore,
  owner: batchDTO.ownerId,
  origin: batchDTO.origin,
});

const mapToBatch = (batch: any): Batch => ({
  id: batch.id,
  lot: batch.lot,
  supplier: batch.producer,
  product: batch.product,
  createdAt: batch.created_at,
  quantity: batch.quantity,
  bestBefore: batch.best_before,
  parentBatches: batch.parent_batches,
  childBatches: batch.child_batches,
  owner: batch.owner,
  origin: batch.origin,
  documents: batch.documents.map(mapBatchDocument),
});

const mapBatchDocument = (document: any): BatchDocument => ({
  name: document.provider_metadata.original_file_name,
  type: document.provider_metadata.file_type,
  downloadUrl: document.url,
});

const mapToProducer = (producer: any): Producer => ({
  id: producer.id,
  name: producer.name,
  registrationCode: producer.registration_code,
  email: producer.email,
  phone: producer.phone,
  tags: producer.tags,
});

const mapToProduct = (product: any): Product => ({
  id: product.id,
  title: product.title,
  producer: product.producer,
  energyKcal: product.kcal,
  energyKj: product.kj,
  fat: product.fat,
  satFat: product.sat_fat,
  carbs: product.carbs,
  sugar: product.sugars,
  protein: product.protein,
  salt: product.salt,
});

const mapToPallet = (pallet: any): Pallet => ({
  type: pallet.type,
  palletItems: pallet.pallet_items.map(mapToPalletItem),
  id: pallet.id,
  warehouse: pallet.warehouse,
  storedAt: pallet.stored_at,
  removedAt: pallet.removed_at,
});

const mapToPalletItem = (palletItem: any): PalletItem => ({
  id: palletItem.id,
  product: palletItem.product,
  quantity: palletItem.quantity,
  batch: palletItem.batch,
  events: palletItem.pallet_item_histories.map(mapToPalletItemEvent),
});

const mapToPalletItemEvent = (event: any): PalletItemEvent => ({
  type: event.event_type,
  oldQuantity: event.old_quantity,
  newQuantity: event.new_quantity,
});

const mapPalletDTO = (pallet: PalletDTO): object => ({
  warehouse: pallet.warehouseId,
  number_of_pallets: pallet.numberOfPallets,
  stored_at: pallet.storedAt,
  type: pallet.type,
  pallet_items: pallet.palletItems.map(mapPalletItemDTO),
});

const mapPalletItemDTO = (palletItem: PalletItemDTO): object => ({
  product_id: palletItem.productId,
  quantity: palletItem.quantity,
  batch_id: palletItem.batchId,
});

export default {
  batch: {
    mapBatchDTO,
    mapToBatch,
  },
  producer: {
    mapToProducer,
  },
  product: {
    mapToProduct,
  },
  pallet: {
    mapToPallet,
    mapPalletDTO,
  },
};
