import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import warehouse, { WarehouseState } from "./Warehouse/reducers";
import auth, { AuthState } from "./Auth/reducers";
import settings, { SettingsState } from "./Settings/reducers";
import files, { FileState } from "./File/reducers";
import telema, {TelemaState} from './Telema/reducers'

import WarehousSaga from "./Warehouse/sagas";
import AuthSaga from "./Auth/sagas";
import SettingsSaga from "./Settings/sagas";
import FileSagas from "./File/sagas";
import TelemaSaga from './Telema/sagas'
export interface AppState {
  warehouse: WarehouseState;
  auth: AuthState;
  settings: SettingsState;
  files: FileState;
  telema: TelemaState
}
const AppReducer = combineReducers({
  warehouse,
  auth,
  settings,
  files,
  telema
});

const AppSaga = function* appSaga() {
  yield all([...WarehousSaga, ...AuthSaga, ...SettingsSaga, ...FileSagas, ...TelemaSaga]);
};

export { AppReducer, AppSaga };
