import React, { useEffect } from "react";
import "antd/dist/antd.css";
import "./App.css";
import { Layout } from "antd";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import { AppReducer, AppSaga } from "./Store";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import AppNavigator from "./AppNavigator";
import { PersistGate } from "redux-persist/integration/react";
import { setApplicationMounted } from "./Store/Settings/actions";
import "./i18n";

const { Header, Footer, Sider, Content } = Layout;
const DEV = true;
const sagaMiddleware = createSagaMiddleware();
const middlewares = DEV ? [sagaMiddleware, logger] : [sagaMiddleware];
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};
const persistedReducer = persistReducer(persistConfig, AppReducer);
export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

let persistor = persistStore(store);
sagaMiddleware.run(AppSaga);

function App() {
  useEffect(() => {
    store.dispatch(setApplicationMounted());
  });
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppNavigator />
      </PersistGate>
    </Provider>
  );
}

export default App;
