import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./types";
import apiClient from "../api";
import {
  DocumentFileActionType,
  DocumentFilePath,
} from "../../Models/DocumentFile";
import { RcFile } from "antd/lib/upload";
import { fetchBatches } from "../Warehouse/actions";

function* handleUploadDocumentFile(action: DocumentFileActionType) {
  try {
    const formData = new FormData();
    let file = action.file.file as RcFile;
    // file.name = `batch/${file.name}`;
    let fileName = action.file.filename.replace("|", ";");
    fileName = [action.file.type, fileName].join("|");
    const newFile = new File([file], fileName, {
      type: file.type,
    });

    formData.append("files", newFile);
    formData.append("ref", action.fileInfo.ref);
    formData.append("refId", action.fileInfo.refId);
    formData.append("path", action.fileInfo.path);
    formData.append("field", action.fileInfo.field);
    //formData.append("source", "content-manager");
    const { data } = yield call(apiClient.post, "upload", formData);
    yield put({ type: types.UPLOAD_FILE_SUCCESS });
    if (action.fileInfo.path === DocumentFilePath.batches) {
      yield put(fetchBatches());
    }
  } catch (e) {
    yield put({ type: types.UPLOAD_FILE_FAILURE });
    console.log(e);
  }
}

const fileSagas = [takeLatest(types.UPLOAD_FILE, handleUploadDocumentFile)];

export default fileSagas;
