import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Select, UploadProps } from "antd";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { AppState } from "../../Store";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import { DocumentFile } from "../../Models/DocumentFile";
import { closeFileModal } from "../../Store/File/actions";
import { useTranslation } from "react-i18next";

interface AddFileModalProps {
  onUpload: (document: DocumentFile) => void;
  isUploading: boolean;
  showModal: boolean;
  closeFileModal: () => void;
  fileTypes: string[];
}

interface ProducerState {
  [key: number]: any;
}
function AddFileModal(props: AddFileModalProps) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [originalFileName, setOriginalFileName] = useState("");
  const [originalFileExtension, setOriginalFileExtension] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      filename: originalFileName,
    });
  }, [originalFileName, originalFileExtension]);

  const uploadProps: UploadProps = {
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);

      let last_dot = file.name.lastIndexOf(".");
      let ext = file.name.slice(last_dot + 1);
      let name = file.name.slice(0, last_dot);
      setOriginalFileName(name);
      setOriginalFileExtension(ext);
      return false;
    },
    showUploadList: true,
    fileList,
  };

  const handleUpload = (values: any) => {
    if (fileList.length <= 0) {
      return;
    }
    const document = {
      filename: `${values.filename}.${originalFileExtension}`,
      file: fileList[0],
      type: values.type,
    };
    props.onUpload(document);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    props.closeFileModal();
  };

  const ModalForm = () => {
    return (
      <Form
        form={form}
        preserve={true}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        onFinish={handleUpload}
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger>
        <Form.Item label="Failinimi" name="filename" style={{ marginTop: 20 }}>
          <Input addonAfter={`.${originalFileExtension}`} />
        </Form.Item>
        <Form.Item
          label="Failitüüp"
          name="type"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Select style={{ width: "100%" }}>
            {props.fileTypes.map((fileType) => {
              return (
                <Select.Option value={fileType} key={fileType}>
                  {t(`batches.documentTypes.${fileType}`)}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      width={800}
      title="Lisa uus fail"
      visible={props.showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Lisa"}
      cancelText={"Tühista"}
      confirmLoading={props.isUploading}
      destroyOnClose={true}
      transitionName={""}
      maskTransitionName={""}
    >
      <ModalForm />
    </Modal>
  );
}
const mapStateToProps = (state: AppState) => ({
  isUploading: state.files.uploadingFile,
  showModal: state.files.fileModalOpen,
});
const mapDispatchToProps = {
  closeFileModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddFileModal);
