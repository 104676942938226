import {Service, ServiceStatus} from "../Models/Service";

function getDataFromService<T>(service: Service<T>): T {
    if (service.status === ServiceStatus.Loaded) {
        return service.payload
    }
    return [] as unknown as T
}

const mmToInch = (mm: number): number => {
    return mm / 25.4
}

const ptToInch = (pt: number): number => {
    return pt / 72
}

const calcDots= (text: string, requriedWidth: number) => {
    const sizes =  [
        {
            width: 55/50,
            chars: "bdghnpquüVTSFEC"
        },
        {
            width: 80/50,
            chars: "mM"
        },
        {
            width: 1,
            chars: "õäüoaeL0123456789"
        },
        {
            width: 46/50,
            chars: "vskcJ"
        },
        {
            width: 33/50,
            chars: "r"
        },
        {
            width: 29/50,
            chars: "tf"
        },
        {
            width: 25/50,
            chars: "ijl"
        },
        {
            width: 63/50,
            chars: "ÜURNHGD"
        },
        {
            width: 59/50,
            chars: "ÕÄÖQPOKBA"
        },
        {
            width: 29/50,
            chars: "I."
        },
        {
            width: 29/50,
            chars: " "
        },
    ]

    let width = 0;
    text.split("").forEach(char => {
        const size = sizes.find(s => s.chars.includes(char))
        if (size !== undefined) {
            width += size.width
        }
    })

    const calculated = (requriedWidth - width) / (29/50)

    return calculated
}

const Utils = {
    getDataFromService,
    mmToInch,
    ptToInch,
    calcDots
}

export default Utils
