import { TelemaOrder, TelemaOrderRef } from "../../Models/TelemaOrder";
import { json2xml } from "xml-js";
import { TelemaBulkOrder } from "../../Models/TelemaBulkOrder";
import { TelemaProduct } from "../../Models/TelemaProduct";

interface TelemaProductWithBoxes extends TelemaProduct {
  boxes: number;
}
export const toXML = (bulkOrder: TelemaBulkOrder) => {
  let products: { [key: string]: TelemaProductWithBoxes } = {};
  (bulkOrder.orders as TelemaOrder[]).forEach((order) => {
    if (order.id) {
    }
    order.products.forEach((p) => {
      if (products[p.sellerGTIN]) {
        products[p.sellerGTIN].quantity += p.quantity;
        products[p.sellerGTIN].boxes += p.quantity / 8;
      } else {
        products[p.sellerGTIN] = {
          ...p,
          boxes: p.quantity / 8,
        };
      }
    });
  });

  const productsJson = Object.values(products).map(
    (productTotal: any, index) => {
      return {
        LineItemNum: index + 1,
        SellerItemCode: productTotal.sellerGTIN,
        ItemDescription: productTotal.productName,
        GTIN: productTotal.sellerGTIN,
        ItemUnitRecord: {
          ItemUnit: "KAST",
        },
        BaseUnit: "KAST",
        AmountOrdered: productTotal.boxes,
      };
    }
  );

  const sourceDocumentsJson = Object.values(
    bulkOrder.orders as TelemaOrder[]
  ).map((order) => {
    return {
      _attributes: {
        type: "CUSTOMER-ORDER",
      },
      SourceDocumentNum: `${order.orderParty.name}-${order.orderNumber}`,
      SourceDocumentDate: order.date,
    };
  });

  const jsonData = {
    "E-Document": {
      Header: {
        DateIssued: bulkOrder.issueDate,
        SenderID: "10389466",
        SenderRegNum: "10389466",
        ReceiverID: "12058696",
        ReceiverRegNum: "12058696",
      },
      Document: {
        DocumentType: "desorder",
        DocumentParties: {
          SellerParty: {
            _attributes: {
              context: "self",
            },
            PartyCode: "FIGURAATA",
            Name: "Figuraata OÜ",
            RegNum: "10389466",
            VATRegNum: "EE100043349",
            ContactData: {
              ActualAddress: {
                Address1: "Jõe 11b",
                City: "Räpina",
                PostalCode: "64505",
                County: "Põlvamaa",
                CountryCode: "EE",
              },
            },
          },
          BuyerParty: {
            _attributes: {
              context: "partner",
            },
            PartyCode: "SELVER",
            Name: "Selver AS",
            RegNum: "10379733",
            VATRegNum: "EE100247019",
            ContactData: {
              ActualAddress: {
                Address1: "Pärnu mnt 238",
                City: "Tallinn",
                PostalCode: "11624",
                County: "Harjumaa",
                CountryCode: "EE",
              },
            },
          },
          DeliveryParty: {
            _attributes: {
              context: "partner",
            },
            PartyCode: "4740581999374",
            Name: "AS Balbiino - Selveri jaotuskeskus",
            RegNum: "10009159",
            ContactData: {
              ActualAddress: {
                Address1: "Viljandi mnt 26",
                City: "Tallinn",
                PostalCode: "11217",
                County: "Harjumaa",
                CountryCode: "EE",
              },
            },
          },
        },
        DocumentInfo: {
          DocumentName: "koondtellimus",
          DocumentNum: bulkOrder.orderNumber,
          DateInfo: {
            OrderDate: bulkOrder.orderDate,
            IssueDate: bulkOrder.issueDate,
            DeliveryDateRequested: bulkOrder.deliveryDate,
          },
          RefInfo: {
            SourceDocument: sourceDocumentsJson,
          },
        },
        DocumentItem: {
          ItemEntry: productsJson,
        },
      },
    },
  };
  //@ts-ignore
  const xmlData = json2xml(jsonData, {
    compact: true,
    ignoreComment: false,
    spaces: 2,
  });
  const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>\n' + xmlData;

  const blob = new Blob([xmlHeader], { type: "text/xml" });
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `O${bulkOrder.orderNumber}.xml`; // Set the desired filename
  downloadLink.click();

  // Clean up the URL object
  URL.revokeObjectURL(downloadLink.href);
};
