import WarehouseSelection from "../../Components/WarehouseSelection/WarehouseSelection";
import React, { useEffect, useState } from "react";
import { Warehouse } from "../../Models/Warehouse";
import WarehouseTable from "../../Components/WarehouseTable/WarehouseTable";
import { Checkbox, Row, Space } from "antd";
import AddPalletModal from "../../Components/WarehouseTable/AddPalletModal";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import AddBatchModal from "../../Components/BatchesTable/AddBatchModal";
import { UserRoleEnum } from "../../Models/Auth";
import { AuthState } from "../../Store/Auth/reducers";
import { AppState } from "../../Store";
import { connect } from "react-redux";
import { fetchBatches } from "../../Store/Warehouse/actions";
import BatchesTable from "../../Components/BatchesTable/BatchesTable";

interface Props {
  auth: AuthState;
  fetchBatches: () => void;
}
function BatchesView(props: Props) {
  const isAdmin =
    props.auth.user && props.auth.user.role.type === UserRoleEnum.ADMIN;

  useEffect(() => {
    props.fetchBatches();
  });

  const TableHeader = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Space>
          <AddBatchModal />
        </Space>
      </Row>
    );
  };

  return (
    <>
      {isAdmin && <TableHeader />}
      <BatchesTable />
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  fetchBatches,
};
export default connect(mapStateToProps, mapDispatchToProps)(BatchesView);
