interface Position {
  x: number;
  y: number;
}

interface Size {
  height: number;
  width?: number;
}

// Adding custom font
// https://www.zebra.com/us/en/support-downloads/knowledge-articles/ait/downloading-and-using-fonts-on-zebra-zpl-printers.html
interface Font {
  fontPath: string;
}

export enum BARCODE_TYPE {
  EAN13 = "BE", // ^BE orientation, height, line, lineAbove
}

export enum Orientation {
  ROTATED_0 = "N",
  ROTATED_90 = "R",
  ROTATED180 = "I",
  ROTATED_270 = "B",
}

export enum Alignment {
  LEFT = "L",
  CENTER = "C",
  RIGHT = "R",
  JUSTIFIED = "J",
}

export interface ZebraBarcode {
  barcodeType: BARCODE_TYPE;
  code: string;
  orientation: Orientation;
  position: Position;
  size: Size;
  type: "ZebraBarcode";
}

export interface ZebraText {
  size: Size;
  font?: Font;
  content: string;
  position: Position;
  type: "ZebraText";
}

export interface ZebraImage {
  content: string;
  type: "ZebraImage";
  position: Position;
  size: Size;
}

export interface ZebraTextBlock {
  size: Size;
  font?: Font;
  content: string;
  position: Position;
  maxLines: number;
  maxWidth: number;
  //lineSpacing: number;
  alignment: Alignment;
  type: "ZebraTextBlock";
}

export interface ZebraGraphicBox {
  size: Size;
  thickness: number;
  position: Position;
  type: "ZebraGraphicBox";
}

export interface ZebraConfig {
  labelWidth: number; //Dots
  labelHeight: number; // Dots
  orientation: Orientation;
  quantity: number;
  flippedHorizontally?: boolean;
}

export type ZebraItem =
  | ZebraText
  | ZebraBarcode
  | ZebraTextBlock
  | ZebraGraphicBox
  | ZebraImage;
