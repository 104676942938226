import axios from "axios";
import ApiClientInterceptor from "./ApiClientInterceptor";

class ApiClient {
  private baseURL: string;
  private count: number;
  // @ts-ignore
  private instance: axios.AxiosInstance;
  private debugMode: boolean;
  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.count = 0;
    this.instance = axios.create({
      baseURL,
      timeout: 10000,
    });
    this.debugMode = true;

    this.instance.interceptors.response.use(undefined, ApiClientInterceptor);
  }

  sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  setAccessToken = (accessToken: string | null) => {
    if (accessToken?.includes("Bearer")) {
      this.instance.defaults.headers.common.Authorization =
        accessToken === null ? null : accessToken;
    } else {
      this.instance.defaults.headers.common.Authorization =
        accessToken === null ? null : `Bearer ${accessToken}`;
    }
  };
  debug = (title: any, content: any) => {
    if (this.debugMode && (title || content)) {
      if (title) {
        console.log(`=== DEBUG: ${title} ===========================`);
      }
      if (content) {
        console.log(content);
      }
    }
  };

  request = async (config: any) => {
    await this.sleep(1000);
    this.debug(
      `API Request #${this.count} to ${this.baseURL}${config.url}`,
      config
    );
    const response = await this.instance.request(config);
    this.debug(
      `API Response #${this.count} from ${this.baseURL}${config.url}`,
      response
    );
    this.count = this.count + 1;

    return response;
  };

  get = async (url: string, params = {}, config = {}) =>
    this.request({
      ...config,
      method: "get",
      url,
      params,
    });

  post = async (url: string, data = {}, config = {}) =>
    this.request({
      ...config,
      method: "post",
      url,
      data,
    });

  patch = async (url: string, data = {}, config = {}) =>
    this.request({
      ...config,
      method: "patch",
      url,
      data,
    });

  put = async (url: string, data = {}, config = {}) =>
    this.request({
      ...config,
      method: "put",
      url,
      data,
    });

  delete = async (url: string, config = {}) =>
    this.request({
      ...config,
      method: "delete",
      url,
    });
}
export default ApiClient;
