import WarehouseSelection from "../../Components/WarehouseSelection/WarehouseSelection";
import React, { useEffect, useState } from "react";
import { Warehouse } from "../../Models/Warehouse";
import WarehouseTable from "../../Components/WarehouseTable/WarehouseTable";
import { Checkbox, Row, Space } from "antd";
import AddPalletModal from "../../Components/WarehouseTable/AddPalletModal";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import AddBatchModal from "../../Components/BatchesTable/AddBatchModal";
import { UserRoleEnum } from "../../Models/Auth";
import { AuthState } from "../../Store/Auth/reducers";
import { AppState } from "../../Store";
import { connect } from "react-redux";
import {
  fetchBatches,
  fetchPallets,
  fetchProducers,
  fetchProducts,
} from "../../Store/Warehouse/actions";

interface WarehouseProps {
  auth: AuthState;
  fetchBatches: () => void;
  fetchPallets: () => void;
  fetchProducers: () => void;
  fetchProducts: () => void;
}
function WarehouseView(props: WarehouseProps) {
  let [selectedWarehouse, setSelectedWarehouse] = useState<
    Warehouse | undefined
  >(undefined);
  let [showArchived, setShowArchived] = useState(false);
  let [revision, setRevision] = useState(1);

  const isAdmin =
    props.auth.user && props.auth.user.role.type === UserRoleEnum.ADMIN;

  useEffect(() => {
    props.fetchBatches();
    props.fetchPallets();
    props.fetchProducers();
    props.fetchProducts();
  });

  const handleWarehouseSelection = (selectedWH: Warehouse | undefined) => {
    setSelectedWarehouse(selectedWH);
  };

  const nextRevision = () => {
    setRevision(revision + 1);
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setShowArchived(e.target.checked);
  };

  const WarehouseTableHeader = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Space>
          <AddBatchModal />
          <AddPalletModal revision={revision} onAdd={() => nextRevision()} />
          <WarehouseSelection
            onSelect={handleWarehouseSelection}
            selectedWarehouse={selectedWarehouse}
          />
          <Checkbox onChange={onCheckboxChange} checked={showArchived}>
            Näita ajalugu
          </Checkbox>
        </Space>
      </Row>
    );
  };

  return (
    <>
      {isAdmin && <WarehouseTableHeader />}
      <WarehouseTable
        warehouse={selectedWarehouse}
        showArchived={showArchived}
        revision={revision}
        onChange={() => nextRevision()}
      />
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  fetchBatches,
  fetchPallets,
  fetchProducers,
  fetchProducts,
};
export default connect(mapStateToProps, mapDispatchToProps)(WarehouseView);
