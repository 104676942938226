import React, {createContext, useContext, useState} from "react";
import Config from "../Config/Config";

export interface UserCredentials {
    identifier: string;
    password: string;
}

export enum UserRoleEnum {
    ADMIN = "authenticated",
    CLIENT = "client"
}

interface UserRole {
    id: number,
    name: string,
    description: string,
    type: UserRoleEnum
}

interface UserModel {
    blocked: boolean
    confirmed: boolean
    created_at: string
    email: string
    id: number
    provider: string
    role: UserRole
    updated_at: string
    username: string
}

interface User {
    jwt: string
    user: UserModel
}

const USER_KEY= "user"

export interface AuthContextModel {
    user: User | null,
    signIn: (a: UserCredentials, b: (c: boolean)=> void) => void,
    signOut: () => void
}

const defaultContextValue: AuthContextModel = {
    user: getUserFromLocalStorage(),
    signIn: (credentials: UserCredentials, callback: (success: boolean) => void) => {},
    signOut: () => {}
}

export const AuthContext = createContext(defaultContextValue)

export function AuthProvider({children}: any) {
    const auth = useAuthProvider()
    return <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
}

function saveUserToLocalStorage(user: any) {
    localStorage.setItem(USER_KEY, JSON.stringify(user))
}

function getUserFromLocalStorage(): User | null {
    let item = localStorage.getItem(USER_KEY)
    if (item !== null) {
        return JSON.parse(item)
    }
    return null
}

function removeUserFromLocalStorage() {
    localStorage.removeItem(USER_KEY)
}

function useAuthProvider() {
    const [user, setUser] = useState(getUserFromLocalStorage());

    const signIn = (credentials: UserCredentials, callback: (success: boolean) => void) => {
        fetch(Config.baseURL + "/auth/local", {
            method: 'POST',
            headers:{'content-type': 'application/json'},
            body: JSON.stringify(credentials)
        }).then(function(response: Response) {
            console.log("response",response)
            if (response.status === 200) {
                response.json().then(user => {
                    // @ts-ignore
                    setUser(user)
                    console.log(user)
                    saveUserToLocalStorage(user)
                    callback(true)
                });
            } else {
                callback(false)
            }
        }).catch(function(error: Error){
            console.log("error", error)
        })
    };

    const signOut = () => {
        setUser(null);
        removeUserFromLocalStorage()
    };

    return {
        user,
        signIn,
        signOut
    };
}

function useAuth() {
    return useContext(AuthContext)
}

export default useAuth
