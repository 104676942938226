import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { TelemaOrder } from "../../Models/TelemaOrder";

const data = [
  { name: "Jan", value: 65 },
  { name: "Feb", value: 59 },
  { name: "Mar", value: 80 },
  { name: "Apr", value: 81 },
  { name: "May", value: 56 },
  { name: "Jun", value: 55 },
];

export interface LineObj {
  id: string;
  name: string;
}

interface Props {
  orders: TelemaOrder[];
  lines: LineObj[];
  dates: string[];
}
const LineChartExample = (props: Props) => {
  const data = props.dates.map((date) => {
    let result: any = {
      date: date,
    };
    const order = props.orders.find((o) => o.date === date);
    if (order) {
      props.lines.forEach((line) => {
        result[line.id] =
          order.products.find((p) => p.sellerGTIN === line.id)?.quantity || 0;
      });
    } else {
      props.lines.forEach((line) => {
        result[line.id] = 0;
      });
    }
    return result;
  });

  const colors = [
    "#FF5733",
    "#5733FF",
    "#33FFFF",
    "#FF33FF",
    "#3399FF",
    "#FF9933",
    "#9933FF",
    "#33FF99",
    "#33FF57",
  ];
  return (
    <LineChart width={600} height={300} data={data}>
      <XAxis dataKey="date" />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      {props.lines.map((line, idx) => {
        return (
          <Line
            type={"monotone"}
            dataKey={line.id}
            name={line.name}
            stroke={colors[idx]}
            activeDot={{ r: 8 }}
          />
        );
      })}
    </LineChart>
  );
};

export default LineChartExample;
