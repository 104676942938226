import RestService from "./RestService";
import Config from "../Config/Config";
import { Label, LabelDTO } from "../Models/Label";
import { mapProduct } from "./ProductService";
import { store } from "../App";

const Post = (
  labelDTO: LabelDTO,
  callback: () => void,
  jwt: string | null | undefined
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(mapDTO(labelDTO)),
  };

  fetch(Config.baseURL + "/labels", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      callback();
      return response;
    })
    .catch((error) => {
      alert(error);
      console.log(error);
    });
};

const Put = (
  labelId: number,
  labelDTO: LabelDTO,
  callback: () => void,
  jwt: string | null | undefined
) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(mapDTO(labelDTO)),
  };

  fetch(Config.baseURL + "/labels/" + labelId, requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      callback();
      return response;
    })
    .catch((error) => {
      alert(error);
      console.log(error);
    });
};

function mapDTO(labelDTO: LabelDTO): object {
  return {
    barcode: labelDTO.barcode,
    weight: labelDTO.weight,
    product_title: labelDTO.title,
    is_organic: labelDTO.isOrganic,
    type: labelDTO.tempType,
    producer_title: labelDTO.producerType,
    product: labelDTO.productId,
    label_type: labelDTO.labelTypeId,
    title_lines: labelDTO.titleLines,
    has_stone_warning: labelDTO.hasStoneWarning,
    ingredients: labelDTO.ingredients,
    has_ingredients: labelDTO.hasIngredients,
    flipped_horizontally: labelDTO.flippedHorizontally,
  };
}

function mapResponse(payload: any): Label[] {
  return payload.map((p: any) => {
    const product = mapProduct(p.product);
    return {
      id: p.id,
      barcode: p.barcode.toString(),
      weight: p.weight,
      titleLines: p.title_lines,
      title: p.product_title,
      isOrganic: p.is_organic,
      tempType: p.type,
      producerType: p.producer_title,
      type: p.label_type,
      product: mapProduct(p.product),
      hasStoneWarning: p.has_stone_warning,
      ingredients: p.ingredients,
      hasIngredients: p.has_ingredients,
      flippedHorizontally: p.flipped_horizontally,
    };
  });
}

const LabelService = () => {
  const state = store.getState();
  const jwt = state.auth.authorizationToken;
  return {
    get: (revision: number) =>
      RestService.get<Label>({
        endpoint: "labels",
        revision,
        mapper: mapResponse,
        filter: "?_limit=-1",
      }),
    post: (labelDTO: LabelDTO, callback: () => void) =>
      Post(labelDTO, callback, jwt),
    put: (labelId: number, labelDTO: LabelDTO, callback: () => void) =>
      Put(labelId, labelDTO, callback, jwt),
  };
};

export default LabelService;
