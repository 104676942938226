import { Product, ProductDTO } from "../Models/Product";
import RestService from "./RestService";
import Config from "../Config/Config";
import { store } from "../App";

const Post = (
  productDTO: ProductDTO,
  callback: () => void,
  jwt: string | null | undefined
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(mapDTO(productDTO)),
  };

  fetch(Config.baseURL + "/products", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      callback();
      return response;
    })
    .catch((error) => {
      alert(error);
      console.log(error);
    });
};

function mapDTO(productDTO: ProductDTO): object {
  return {
    title: productDTO.title,
    kcal: productDTO.energyKcal,
    kj: productDTO.energyKj,
    fat: productDTO.fat,
    sat_fat: productDTO.satFat,
    carbs: productDTO.carbs,
    sugars: productDTO.sugar,
    protein: productDTO.protein,
    salt: productDTO.salt,
    producer: productDTO.producerId,
  };
}

function mapResponse(payload: any): Product[] {
  return payload.map((p: any) => {
    return mapProduct(p);
  });
}

export function mapProduct(p: any): Product {
  return {
    id: p.id,
    title: p.title,
    producer: p.producer,
    energyKcal: p.kcal,
    energyKj: p.kj,
    fat: p.fat,
    satFat: p.sat_fat,
    carbs: p.carbs,
    sugar: p.sugars,
    protein: p.protein,
    salt: p.salt,
  };
}

const ProductService = () => {
  const state = store.getState();
  const jwt = state.auth.authorizationToken;
  return {
    get: (revision: number) =>
      RestService.get<Product>({
        endpoint: "products",
        revision,
        mapper: mapResponse,
      }),
    post: (productDTO: ProductDTO, callback: () => void) =>
      Post(productDTO, callback, jwt),
  };
};

export default ProductService;
