import { Space, Table } from "antd";
import "./Table.style.css";
import { ServiceStatus } from "../../Models/Service";
import React from "react";
import { Label } from "../../Models/Label";
import { LabelType } from "../../Models/LabelType";
import LabelService from "../../Services/LabelService";
import PrintLabelModal from "./PrintLabelModal";
import { SortOrder } from "antd/lib/table/interface";
import EditLabelModal from "./EditLabelModal";
import {
  LabelBoxFilter,
  LabelLanguageFilter,
  LabelWeightFilter,
} from "../../Views/Labels/LabelsView";

interface LabelsTableProps {
  onChange: () => void;
  revision: number;
  languageFilter: LabelLanguageFilter[];
  weightFilter: LabelWeightFilter[];
  boxFilter: LabelBoxFilter[];
}

interface RowType extends Label {
  counter: number;
}

type FilterType = string | number | boolean;

function LabelsTable(props: LabelsTableProps) {
  let labelService = LabelService().get(props.revision);

  const RenderTable = (labels: Label[]) => {
    let data: RowType[] = labels.map((l, idx) => {
      return {
        ...l,
        counter: idx + 1,
      };
    });

    const columns = [
      /*{
                title: '#',
                dataIndex: 'counter',
                key: 'counter',
                render: (count: number) => <strong>{count}</strong>,
            },*/
      {
        title: "Nimetus",
        dataIndex: "title",
        key: "title",
        sorter: (a: RowType, b: RowType) => a.title.localeCompare(b.title),
        render: (title: string) => <strong>{title}</strong>,
        defaultSortOrder: "ascend" as SortOrder,
      },
      {
        title: "Koostis",
        dataIndex: "ingredients",
        key: "ingredients",
        render: (ingredients: string) => <strong>{ingredients}</strong>,
      },
      {
        title: "Kaal",
        dataIndex: "weight",
        key: "weight",
        render: (weight: number) => <strong>{weight / 1000} kg</strong>,
      },
      {
        title: "Tüüp",
        dataIndex: "type",
        key: "labelType",
        render: (type: LabelType) => <strong>{type.title}</strong>,
      },
      {
        title: "EAN",
        dataIndex: "barcode",
        key: "barcode",
        render: (ean: string) => <strong>{ean}</strong>,
      },
      {
        title: "Action",
        key: "action",
        render: (_: any, record: RowType) => (
          <Space size="middle">
            <PrintLabelModal
              revision={props.revision}
              label={record}
              onPrint={props.onChange}
            />
            <EditLabelModal
              onAdd={props.onChange}
              revision={props.revision}
              label={record}
            />
          </Space>
        ),
      },
    ];

    //console.log(data)
    const { languageFilter, weightFilter, boxFilter } = props;
    data = data.filter((row) => {
      var result = true;
      if (languageFilter.length > 0) {
        if (languageFilter.includes(LabelLanguageFilter.EE)) {
          result =
            (result && row.type.title === "Figuraata värviline 150x100mm") ||
            row.type.title === "Figuraata värviline 85x100mm";
        }
        if (languageFilter.includes(LabelLanguageFilter.EN)) {
          result = result && row.type.title.includes("eng");
        }
        if (languageFilter.includes(LabelLanguageFilter.EE_EN)) {
          result = result && row.type.title.includes("ee,en");
        }
      }
      if (weightFilter.length > 0) {
        if (weightFilter.includes(LabelWeightFilter.LESS_THAN_1_KG)) {
          result = result && row.weight < 1000;
        }
        if (weightFilter.includes(LabelWeightFilter.ONE_KG)) {
          result = result && row.weight === 1000;
        }
        if (weightFilter.includes(LabelWeightFilter.TWO_HALF_KG)) {
          result = result && row.weight === 2500;
        }
        if (weightFilter.includes(LabelWeightFilter.MORE_THAN_2_HALF)) {
          result = result && row.weight > 2500;
        }
      }

      if (boxFilter.length > 0) {
        if (boxFilter.includes(LabelBoxFilter.BOX)) {
          result = result && row.type.title.includes("85x100mm");
        }
        if (boxFilter.includes(LabelBoxFilter.BAG)) {
          result = result && row.type.title.includes("150x100mm");
        }
      }

      return result;
    });

    return (
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        pagination={false}
      />
    );
  };

  const Render = () => {
    if (labelService.status === ServiceStatus.Loading) {
      return <div>Loading </div>;
    }
    if (labelService.status === ServiceStatus.Loaded) {
      return RenderTable(labelService.payload);
    }
    return <div>{labelService.status}</div>;
  };
  return <Render />;
}
export default LabelsTable;
