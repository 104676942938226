import { Table } from "antd";
import { Pallet } from "../../Models/Pallet";
import { Warehouse } from "../../Models/Warehouse";
import { PalletItem } from "../../Models/PalletItem";
import "./Table.style.css";
import { ColumnFilterItem } from "antd/es/table/interface";
import DeletePalletModal from "./DeletePalletModal";
import React from "react";
import { connect } from "react-redux";
import { AppState } from "../../Store";
import { AuthState } from "../../Store/Auth/reducers";
import { UserRoleEnum } from "../../Models/Auth";

interface WarehousePalletTableProps {
  pallets: Pallet[];
  showArchived: boolean;
  onChange: () => void;
  revision: number;
  auth: AuthState;
}

interface RowType extends Pallet {
  totalQuantity: number;
  counter: number;
}

type FilterType = string | number | boolean;

function WarehousePalletTable(props: WarehousePalletTableProps) {
  let productFilter: ColumnFilterItem[] = [];
  let producerFilter: ColumnFilterItem[] = [];
  const isAdmin =
    props.auth.user && props.auth.user.role.type === UserRoleEnum.ADMIN;

  let data: RowType[] = props.pallets.map((p, idx) => {
    let q = 0;
    let totalQuantity = 0;
    p.palletItems.forEach((p) => {
      totalQuantity += p.quantity;
      if (productFilter.filter((f) => f.value === p.product.id).length === 0) {
        productFilter.push({
          value: p.product.id,
          text: p.product.title,
        });
      }
      if (
        producerFilter.filter((f) => f.value === p.product.producer.id)
          .length === 0
      ) {
        producerFilter.push({
          value: p.product.producer.id,
          text: p.product.producer.name,
        });
      }
    });
    return {
      ...p,
      totalQuantity,
      counter: idx + 1,
    };
  });

  const columns = [
    {
      title: "#",
      dataIndex: "counter",
      key: "counter",
      render: (count: number) => <strong>{count}</strong>,
    },
    {
      title: "Aluse tüüp",
      dataIndex: "type",
      key: "type",
      render: (type: string) => <strong>{type}</strong>,
    },
    {
      title: "Ladu",
      dataIndex: "warehouse",
      key: "warehouse",
      render: (wh: Warehouse) => <strong>{wh.name}</strong>,
      sorter: (a: Pallet, b: Pallet) =>
        a.warehouse.name > b.warehouse.name ? 1 : -1,
    },
    {
      title: "Tooted",
      dataIndex: "palletItems",
      key: "palletItems",
      filters: productFilter,
      onFilter: (value: FilterType, record: RowType) =>
        record.palletItems.filter((r) => r.product.id === value).length > 0,
      render: (products: PalletItem[]) => (
        <strong>
          {products.map((p, index) => (
            <div key={index}>
              <div>{p.product.title}</div>
            </div>
          ))}
        </strong>
      ),
    },
    {
      title: "Parim enne",
      dataIndex: "palletItems",
      key: "bestBefore",
      render: (products: PalletItem[]) => (
        <strong>
          {/*products.map((p, index) => {
            return RenderDate(p.expirationDate, index);
          })*/}
          TODO
        </strong>
      ),
    },
    {
      title: "Kogus",
      dataIndex: "palletItems",
      key: "bestBefore",
      render: (products: PalletItem[]) => (
        <strong>
          {products.map((p, index) => (
            <div key={index}>
              <div>{p.quantity}</div>
            </div>
          ))}
        </strong>
      ),
    },
    {
      title: "Omanik",
      dataIndex: "palletItems",
      key: "producer",
      filters: producerFilter,
      onFilter: (value: FilterType, record: RowType) =>
        record.palletItems.filter((r) => r.product.producer.id === value)
          .length > 0,
      render: (products: PalletItem[]) => (
        <strong>
          {products.map((p, index) => (
            <div key={index}>
              <div>{p.product.producer.name}</div>
            </div>
          ))}
        </strong>
      ),
    },
    {
      title: "Lisatud",
      dataIndex: "storedAt",
      key: "storedAt",
      render: (storedAt: string) => <strong>{RenderDate(storedAt, 1)}</strong>,
    },
    {
      title: "Väljastatud",
      dataIndex: "removedAt",
      key: "removedAt",
      render: (removedAt: string) => (
        <strong>{RenderDate(removedAt, 1)}</strong>
      ),
    },
    {
      title: "Aluse kaal",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      render: (quantity: number) => <strong>{quantity} kg</strong>,
    },
    {
      title: "Tegevused",
      dataIndex: "operation",
      key: "operation",
      render: (_: any, record: RowType) =>
        record.removedAt === null && (
          <DeletePalletModal onDelete={props.onChange} pallet={record} />
        ),
    },
  ]
    .filter((c) => isAdmin || (c.key !== "warehouse" && c.key !== "operation"))
    .filter((c) => c.key !== "removedAt" || props.showArchived); // Show 'väljastatud' only when checkbox is checked

  const RenderDate = (date: string, key: number) => {
    let d = new Date(date);
    return (
      <div key={key}>
        <div>
          {date
            ? d.toLocaleDateString("et-EE", {
                // you can use undefined as first argument
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : "-"}
        </div>
      </div>
    );
  };

  return (
    <Table
      className="table-striped-rows"
      columns={columns}
      dataSource={data}
      rowKey={"id"}
      pagination={false}
    />
  );
}

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
  pallets: state.warehouse.pallet.pallets,
  producers: state.warehouse.producer.producers,
  products: state.warehouse.product.products,
});

const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WarehousePalletTable);
