import React, { useEffect, useState } from "react";
import { AppState } from "../../Store";
import { fetchOrders } from "../../Store/Telema/actions";
import { connect } from "react-redux";
import { TelemaOrder } from "../../Models/TelemaOrder";
import { Modal, Table } from "antd";
import { TelemaPartner } from "../../Models/TelemaPartner";
import { ColumnProps } from "antd/lib/table";
import LineChartExample, {
  LineObj,
} from "../../Components/LineChart/LineChart";

interface GroupedOrder {
  orderedBy: TelemaPartner;
  orders: TelemaOrder[];
}

interface TelemaStatisticsProps {
  fetchOrders: () => void;
  orders: GroupedOrder[];
}

const TelemaStatistics = (props: TelemaStatisticsProps) => {
  const [activeOrder, setActiveOrder] = useState<GroupedOrder | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    props.fetchOrders();
  }, []);

  useEffect(() => {
    console.log("ORDERS", props.orders);
  }, [props.orders]);

  const columns: ColumnProps<GroupedOrder>[] = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => <strong>{index + 1}</strong>,
    },
    {
      title: "Tellija",
      dataIndex: "orderedBy",
      key: "orderedBy",
      render: (value, item) => <span>{item.orderedBy.name}</span>,
    },
    {
      title: "Viimane tellimuse kp",
      dataIndex: "lastOrderDate",
      key: "lastOrderDate",
      render: (value, item) => (
        <span>{new Date(item.orders[0].date).toLocaleDateString()}</span>
      ),
    },
    {
      title: "Tellimuste arv kokku",
      dataIndex: "totalOrders",
      key: "totalOrders",
      render: (value, item) => <span>{item.orders.length}</span>,
    },
    {
      title: "Tellimuste statistika",
      dataIndex: "stats",
      key: "stats",
      render: (value, item) => (
        <button onClick={() => openStatistics(item)}>Statistika</button>
      ),
    },
  ];

  const openStatistics = (order: GroupedOrder) => {
    setActiveOrder(order);
    setModalVisible(true);
  };

  const getLinesForOrder = (orders: TelemaOrder[]) => {
    let lines: LineObj[] = [];
    orders.forEach((o) => {
      o.products.forEach((p) => {
        if (!lines.find((l) => l.id === p.sellerGTIN)) {
          lines.push({
            id: p.sellerGTIN,
            name: p.productName,
          });
        }
      });
    });
    return lines;
  };

  const handleCancel = () => {
    setModalVisible(false);
    setActiveOrder(null);
  };

  const getDatesFromOrders = (orders: GroupedOrder[]) => {
    let dates: string[] = [];
    orders.forEach((o) => {
      o.orders.forEach((o2) => {
        if (!dates.includes(o2.date)) {
          dates.push(o2.date);
        }
      });
    });
    return dates.sort(
      // @ts-ignore
      (a, b) => new Date(a) - new Date(b)
    );
  };

  return (
    <>
      <Modal
        width={800}
        title={activeOrder?.orderedBy.name}
        visible={modalVisible}
        onCancel={handleCancel}
        cancelText={"Sulge"}
        okButtonProps={{ style: { display: "none" } }}
        destroyOnClose={true}
      >
        {activeOrder && (
          <LineChartExample
            orders={activeOrder.orders}
            lines={getLinesForOrder(activeOrder.orders)}
            dates={getDatesFromOrders(props.orders)}
          />
        )}
      </Modal>
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={props.orders}
        rowKey={"orderedBy.GLN"}
        pagination={false}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  orders: _.chain(state.telema.orders)
    .groupBy("orderParty.GLN")
    .map((data, gln) => {
      const sortedOrders = data.sort(
        // @ts-ignore
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      return {
        orderedBy: data[0].orderParty,
        lastOrderDate: sortedOrders[0].date,
        orders: sortedOrders,
      };
    })
    .sortBy("lastOrderDate")
    .reverse()
    .value(),
});

const mapDispatchToProps = {
  fetchOrders,
};
export default connect(mapStateToProps, mapDispatchToProps)(TelemaStatistics);
