import React from "react";
import { Button, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { TelemaBulkOrder } from "../../Models/TelemaBulkOrder";
import { TelemaDeliveryNote } from "../../Models/TelemaDeliveryNote";
import { TelemaProduct } from "../../Models/TelemaProduct";

const _ = require("lodash");

interface SingleWarehouseProps {
  orders: TelemaBulkOrder[];
  downloadXML: (id: number) => void;
  importDeliveryNotes: () => void;
  exportDeliveryNote: (bulkOrderId: number) => void;
}

function TelemaBulkOrdersTable(props: SingleWarehouseProps) {
  const columns: ColumnProps<TelemaBulkOrder>[] = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => (
        <strong>
          {index + 1} {displayAlert(item)}
        </strong>
      ),
    },
    {
      title: "Tellimuse number",
      dataIndex: "orderNumber",
      key: "orderNumber",
      render: (orderNumber: string) => <strong>{orderNumber}</strong>,
    },
    {
      title: "Tellimuse kuupäev",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (orderDate: string) => <strong>{orderDate}</strong>,
    },
    {
      title: "Tarne kuupäev",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (deliveryDate: string) => <strong>{deliveryDate}</strong>,
    },
    {
      title: "Tellimused",
      dataIndex: "orders",
      key: "orders",
      render: (_: any, row) => (
        <strong>{row.orders.map((r) => r.orderNumber).join(", ")}</strong>
      ),
    },
    {
      title: "Saateleht",
      dataIndex: "deliveryNote",
      key: "deliveryNote",
      render: (deliveryNote: TelemaDeliveryNote) => (
        <strong>{deliveryNote && deliveryNote.documentNumber}</strong>
      ),
    },
    {
      title: "Tegevused",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, row) => (
        <>
          <Button onClick={() => downloadXML(row.id)}>
            Koondtellimuse XML
          </Button>
          <Button onClick={() => createDeliveryNote(row)}>
            Koosta Telema saateleht
          </Button>
        </>
      ),
    },
  ];

  const createDeliveryNote = (order: TelemaBulkOrder) => {
    props.exportDeliveryNote(order.id);
  };

  const downloadXML = (orderId: number) => {
    props.downloadXML(orderId);
  };

  const loadNordNetXML = () => {
    props.importDeliveryNotes();
  };

  const displayAlert = (order: TelemaBulkOrder) => {
    if (!order.deliveryNote) {
      return null;
    }

    let orderedProducts: { [key: string]: TelemaProduct } = {};
    let sentProducts = order.deliveryNote.products;
    let count = 0;
    let multipleBatches = false;
    let originMissing = false;
    let productsFromMultipleBatches: string[] = [];
    let errors: string[] = [];
    let originMissingFor: string[] = [];
    order.orders.map((o) => {
      o.products.forEach((p) => {
        if (orderedProducts[p.sellerGTIN]) {
          orderedProducts[p.sellerGTIN].quantity += p.quantity;
        } else {
          orderedProducts[p.sellerGTIN] = { ...p };
          count += 1;
        }
      });
    });

    order.deliveryNote.products.forEach((p) => {
      if (p.batches && p.batches.length > 1) {
        multipleBatches = true;
        productsFromMultipleBatches.push(p.productName);
      }
      if (p.origin.toLowerCase().includes("given")) {
        originMissing = true;
        originMissingFor.push(p.productName);
      }
    });

    let displayAlert = count !== sentProducts.length;
    Object.values(orderedProducts).forEach((p) => {
      const sentProduct = sentProducts.find(
        (sp) => sp.sellerGTIN === p.sellerGTIN
      );
      if (sentProduct === undefined) {
        displayAlert = true;
      } else if (sentProduct.deliveredQuantity !== p.quantity) {
        displayAlert = true;
      }
    });

    if (displayAlert) {
      errors.push("MistMatchAlert");
    }

    if (multipleBatches) {
      errors.push(
        `Kaup mitmest partiist: ${productsFromMultipleBatches.join(", ")}`
      );
    }

    if (originMissing) {
      errors.push(`Päritolu puudubt: ${originMissingFor.join(", ")}`);
    }

    if (errors.length > 0) {
      return <span style={{ color: "red" }}>{errors.join(", ")}</span>;
    }
    return null;
  };

  console.log(props.orders);

  if (props.orders[0]) {
    console.log(props.orders[0].deliveryNote?.products);
  }

  props.orders.forEach((o) => {
    o.deliveryNote?.products.forEach((p) => {
      if (p.batches && p.batches?.length > 1) {
        console.log(o.deliveryNote?.documentNumber, p.productName);
      }
    });
  });
  return (
    <>
      <Button onClick={loadNordNetXML}>Laadi nordneti saatelehed</Button>
      <Table
        className="table-striped-rows"
        columns={columns}
        dataSource={props.orders}
        rowKey={"id"}
        pagination={false}
      />
    </>
  );
}
export default TelemaBulkOrdersTable;
