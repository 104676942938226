import { Warehouse } from "../../Models/Warehouse";
import React from "react";
import WarehousePalletTable from "./WarehousePalletTable";

interface SingleWarehouseProps {
  warehouse?: Warehouse;
  revision: number;
  showArchived: boolean;
  onChange: () => void;
}

function WarehouseTable(props: SingleWarehouseProps) {
  return (
    <WarehousePalletTable
      onChange={props.onChange}
      showArchived={props.showArchived}
      revision={props.revision}
    />
  );
}
export default WarehouseTable;
