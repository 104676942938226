import React, { useEffect, useState } from "react";
import { Producer } from "../../Models/Producer";
import { Button, Row, Table } from "antd";
import { AppState } from "../../Store";
import { connect } from "react-redux";
import {
  allBatchDocumentTypes,
  Batch,
  BatchDocument,
} from "../../Models/Batch";
import { fetchBatches } from "../../Store/Warehouse/actions";
import { Product } from "../../Models/Product";
import AddFileModal from "../FileModal/AddFileModal";
import {
  DocumentFile,
  DocumentFileInfo,
  DocumentFilePath,
  DocumentFileRef,
} from "../../Models/DocumentFile";
import { openFileModal, uploadDocumentFile } from "../../Store/File/actions";
import { useTranslation } from "react-i18next";

interface BatchesTableProps {
  batches: Batch[];
  fetchBatches: () => void;
  uploadDocumentFile: (file: DocumentFile, fileInfo: DocumentFileInfo) => void;
  openFileModal: () => void;
}

interface RowType extends Batch {
  counter: number;
}

const BatchesTable = (props: BatchesTableProps) => {
  const [activeBatch, setActiveBatch] = useState<Batch | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    props.fetchBatches();
  }, []);

  const openAddFileModal = (batch: Batch) => {
    setActiveBatch(batch);
    props.openFileModal();
  };

  const onUploadFile = (file: DocumentFile) => {
    if (activeBatch === null) {
      return;
    }

    let newFile = {
      ...file,
      filename: [
        activeBatch.id,
        activeBatch.product.id,
        activeBatch.supplier.id,
        activeBatch.lot,
        file.filename,
      ].join(";"),
    };
    props.uploadDocumentFile(newFile, {
      path: DocumentFilePath.batches,
      refId: activeBatch.id.toString(),
      ref: DocumentFileRef.batches,
      field: "documents",
    });
  };
  const RenderTable = () => {
    let data: RowType[] = props.batches.map((p, idx) => {
      return {
        ...p,
        counter: idx + 1,
      };
    });

    const renderDocument = (document: BatchDocument) => {
      return (
        <Row>
          <Button
            type={"link"}
            onClick={() => window.open(document.downloadUrl)}
          >
            {document.name} | {t(`batches.documentTypes.${document.type}`)}
          </Button>
        </Row>
      );
    };

    const columns = [
      {
        title: "#",
        dataIndex: "counter",
        key: "counter",
        render: (count: number) => <strong>{count}</strong>,
      },
      {
        title: "Artikkel",
        dataIndex: "product",
        key: "product",
        render: (product: Product) => <strong>{product.title}</strong>,
      },
      {
        title: "Omanik",
        dataIndex: "owner",
        key: "owner",
        render: (owner: Producer) => <strong>{owner.name}</strong>,
      },
      {
        title: "Tootja",
        dataIndex: "supplier",
        key: "supplier",
        render: (producer: Producer) => <strong>{producer.name}</strong>,
      },
      {
        title: "Kogus (kg)",
        dataIndex: "quantity",
        key: "quantity",
        render: (quantity: number) => <strong>{quantity}</strong>,
      },
      {
        title: "Lot",
        dataIndex: "lot",
        key: "lot",
        render: (lot: string) => <strong>{lot}</strong>,
      },
      {
        title: "Parim enne",
        dataIndex: "bestBefore",
        key: "bestBefore",
        render: (bestBefore: string) => <strong>{bestBefore}</strong>,
      },
      {
        title: "Päritolu",
        dataIndex: "origin",
        key: "origin",
        render: (origin: string) => <strong>{origin}</strong>,
      },
      {
        title: "Failid",
        dataIndex: "documents",
        key: "documents",
        render: (documents: BatchDocument[], batch: RowType) => (
          <>{documents.map(renderDocument)}</>
        ),
      },
      {
        title: "Tegevused",
        dataIndex: "documents",
        key: "documents",
        render: (documents: any[], batch: RowType) => (
          <Button type={"link"} onClick={() => openAddFileModal(batch)}>
            Lisa fail
          </Button>
        ),
      },
    ];

    return (
      <>
        <AddFileModal
          onUpload={(file) => onUploadFile(file)}
          fileTypes={allBatchDocumentTypes}
        />
        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={data}
          rowKey={"id"}
          pagination={false}
        />
      </>
    );
  };

  return <RenderTable />;
};

const mapStateToProps = (state: AppState) => ({
  batches: state.warehouse.batch.batches,
});

const mapDispatchToProps = {
  fetchBatches,
  openFileModal,
  uploadDocumentFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchesTable);
