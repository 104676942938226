import { useState } from "react";
import { Button, Form, Input, InputNumber, Modal, Radio, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Service, ServiceStatus } from "../../Models/Service";
import ProducerService from "../../Services/ProducerService";
import { Producer } from "../../Models/Producer";
import ProductService from "../../Services/ProductService";
import { Product, ProductDTO } from "../../Models/Product";
import moment from "moment";
import Utils from "../../Utils/Utils";

interface AddProductModalProps {
  onAdd: () => void;
  revision: number;
}

function AddProductNodal(props: AddProductModalProps) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [wasAdded, setWasAdded] = useState(false);
  const [form] = Form.useForm();
  const producerService = ProducerService().get(props.revision);
  const productService = ProductService();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const handleProductCreated = () => {
    setWasAdded(true);
    setVisible(false);
    setConfirmLoading(false);
  };

  function handleFinish(values: any) {
    const dto: ProductDTO = {
      carbs: values.carbs,
      energyKcal: values.energyKcal,
      energyKj: values.energyKj,
      fat: values.fat,
      producerId: values.producer,
      protein: values.protein,
      salt: values.salt,
      satFat: values.satFat,
      sugar: values.sugar,
      title: values.title,
    };
    setConfirmLoading(true);
    productService.post(dto, handleProductCreated);
  }

  const handleModalDestroy = () => {
    if (wasAdded) {
      props.onAdd();
    }
  };

  function getProducers(): Producer[] {
    return Utils.getDataFromService(producerService);
  }

  function getProducts(): Product[] {
    return Utils.getDataFromService(productService.get(props.revision));
  }

  const getDefaultValue = (
    arr: any[],
    selectedValue?: any
  ): number | undefined => {
    if (selectedValue !== undefined) {
      return selectedValue.id;
    }
    if (arr.length > 0) {
      return arr[0].id;
    }
    return undefined;
  };

  const onInputChange = (e: any) => {
    console.log("value", e);
    const value = e.target.value;
    const products = value.replaceAll(",", ".").split("|");

    products.forEach((product: any) => {
      const parts = product.split(";");
      const dto = {
        title: parts[0],
        energyKj: parts[1],
        energyKcal: parts[2],
        fat: parts[3],
        satFat: parts[4],
        carbs: parts[5],
        sugar: parts[6],
        protein: parts[7],
        salt: parts[8],
        producerId: 1,
      };
      productService.post(dto, () => {});
    });
    /*if (parts.length === 9) {
            console.log("YAY")
            form.setFieldsValue({
                title: parts[0],
                energyKj: parts[1],
                energyKcal: parts[2],
                fat: parts[3],
                satFat: parts[4],
                carbs: parts[5],
                sugar: parts[6],
                protein: parts[7],
                salt: parts[8],
                producerId: 1
            })
        }*/
    console.log(value);
  };

  const ModalForm = () => {
    let defaultProducer = getDefaultValue(getProducers());
    return (
      <Form
        form={form}
        preserve={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{
          type: "EUR",
          products: [""],
          producer: defaultProducer,
          numberOfPallets: 1,
          storedAt: moment(),
        }}
        onFinish={handleFinish}
      >
        <Form.Item label="drive" name={"Drive"}>
          <input onChange={(v) => onInputChange(v)} />
        </Form.Item>
        <Form.Item label="Nimetus" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Tootja" name={"producer"}>
          <Select style={{ width: "100%" }}>
            {getProducers().map((producer) => (
              <Select.Option key={producer.id} value={producer.id}>
                {producer.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Energia (kcal)" name="energyKcal">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Energia (KJ)" name="energyKj">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Rasvad (g)" name="fat">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Küllastunud rasvad (g)" name="satFat">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Süsivesikud (g)" name="carbs">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Suhkrud (g)" name="sugar">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Valgud (g)" name="protein">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Sool (g)" name="salt">
          <InputNumber min={0} />
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        Lisa uus artikkel
      </Button>
      <Modal
        width={800}
        title="Lisa uus artikkel"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleModalDestroy}
        okText={"Lisa"}
        cancelText={"Tühista"}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <ModalForm />
      </Modal>
    </>
  );
}

export default AddProductNodal;
