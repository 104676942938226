import { Table } from "antd";
import "./Table.style.css";
import { ColumnFilterItem } from "antd/es/table/interface";
import { Product } from "../../Models/Product";
import ProductService from "../../Services/ProductService";
import { ServiceStatus } from "../../Models/Service";
import WarehousePalletTable from "../WarehouseTable/WarehousePalletTable";
import React, { useState } from "react";
import { Producer } from "../../Models/Producer";

interface ProductsTableProps {
  onChange: () => void;
  revision: number;
}

interface RowType extends Product {
  counter: number;
}

type FilterType = string | number | boolean;

function ProductsTable(props: ProductsTableProps) {
  let productFilter: ColumnFilterItem[] = [];
  let producerFilter: ColumnFilterItem[] = [];
  let productService = ProductService().get(props.revision);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);

  const RenderTable = (products: Product[]) => {
    let data: RowType[] = products.map((p, idx) => {
      if (productFilter.filter((f) => f.value === p.id).length === 0) {
        productFilter.push({
          value: p.id,
          text: p.title,
        });
      }
      if (
        producerFilter.filter((f) => f.value === p.producer.id).length === 0
      ) {
        producerFilter.push({
          value: p.producer.id,
          text: p.producer.name,
        });
      }
      return {
        ...p,
        counter: idx + 1,
      };
    });

    const columns = [
      {
        title: "#",
        dataIndex: "counter",
        key: "counter",
        render: (count: number) => <strong>{count}</strong>,
      },
      {
        title: "Artikkel",
        dataIndex: "title",
        key: "title",
        render: (title: string) => <strong>{title}</strong>,
      },
      {
        title: "Tootja",
        dataIndex: "producer",
        key: "producer",
        filters: producerFilter,
        onFilter: (value: FilterType, record: RowType) =>
          record.producer.id === value,
        render: (producer: Producer) => <strong>{producer.name}</strong>,
      },
      {
        title: "Tegevused",
        dataIndex: "kcal",
        key: "kcal",
        onFilter: (value: FilterType, record: RowType) =>
          record.producer.id === value,
        render: (_: string, row: Product) => (
          <>
            <button
              onClick={() =>
                navigator.clipboard.writeText(
                  `kj:${row.energyKj} | kcal:${row.energyKcal} | fat:${row.fat} | satfat:${row.satFat} | carbs:${row.carbs} | sugar:${row.sugar} | protein:${row.protein} | salt:${row.salt}`
                )
              }
            >
              Copy
            </button>
            <button
              onClick={() => setSelectedProducts([...selectedProducts, row])}
            >
              Add
            </button>
          </>
        ),
      },
    ];

    const calculateAvg = () => {
      if (selectedProducts.length === 0) {
        return null;
      }
      let p = {
        ...selectedProducts[0],
      };

      for (let i = 1; i < selectedProducts.length; i++) {
        let pi = selectedProducts[i];
        p.fat += pi.fat;
        p.salt += pi.salt;
        p.protein += pi.protein;
        p.sugar += pi.sugar;
        p.carbs += pi.carbs;
        p.satFat += pi.satFat;
        p.energyKcal += pi.energyKcal;
        p.energyKj += pi.energyKj;
      }
      return {
        fat: p.fat / selectedProducts.length,
        salt: p.salt / selectedProducts.length,
        protein: p.protein / selectedProducts.length,
        sugar: p.sugar / selectedProducts.length,
        carbs: p.carbs / selectedProducts.length,
        satFat: p.satFat / selectedProducts.length,
        energyKcal: p.energyKcal / selectedProducts.length,
        energyKj: p.energyKj / selectedProducts.length,
      };
    };

    const avg = calculateAvg();
    return (
      <>
        <div>
          <div>{selectedProducts.map((p) => p.title).join(",")}</div>
          {avg &&
            Object.keys(avg).map((key: any) => (
              <div>
                {key} {avg[key as keyof typeof avg].toFixed(2)}
              </div>
            ))}
        </div>
        <Table
          className="table-striped-rows"
          columns={columns}
          dataSource={data}
          rowKey={"id"}
          pagination={false}
        />
      </>
    );
  };

  const Render = () => {
    if (productService.status === ServiceStatus.Loading) {
      return <div>Loading </div>;
    }
    if (productService.status === ServiceStatus.Loaded) {
      return RenderTable(productService.payload);
    }
    return <div>{productService.status}</div>;
  };
  return <Render />;
}
export default ProductsTable;
