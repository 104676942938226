import React, { useEffect } from "react";
import { AppState } from "../../Store";
import { connect } from "react-redux";
import {
    createBulkOrder, createInvoicesInMerit,
    downloadBulkOrderXML, exportBulkOrderToTelema,
    fetchBulkOrders,
    fetchOrders, fetchRecAdvs, importDeliveryNotes, importRecAdvsFromTelema,
} from "../../Store/Telema/actions";
import TelemaOrdersTable from "../../Components/TelemaOrdersTable/TelemaOrdersTable";
import { TelemaOrder } from "../../Models/TelemaOrder";
import {
    TelemaBulkOrder,
    TelemaBulkOrderDTO,
} from "../../Models/TelemaBulkOrder";
import TelemaBulkOrdersTable from "../../Components/TelemaBulkOrdersTable/TelemaBulkOrdersTable";
import TelemaRecAdvsTable from "../../Components/TelemaRecAdvsTable/TelemaRecAdvsTable";
import {TelemaRecAdv} from "../../Models/TelemaRecAdv";

interface OrdersProps {
    fetchRecAdvs: () => void;
    importRecAdvsFromTelema: () => void;
    createInvoicesInMerit: (ids: number[]) => void;
    recAdvs: TelemaRecAdv[]
}
function TelemaRecAdvs(props: OrdersProps) {
    useEffect(() => {
        props.fetchRecAdvs();
    }, []);

    return (
        <>
            <TelemaRecAdvsTable
                recAdvs={props.recAdvs}
                importRecAdvs={props.importRecAdvsFromTelema}
                createInvoices={props.createInvoicesInMerit}
            />
        </>
    );
}

const mapStateToProps = (state: AppState) => ({
    // @ts-ignore
    recAdvs: state.telema.recAdvs.sort(
        // @ts-ignore
        (a, b) => b.id - a.id
    ),
});

const mapDispatchToProps = {
    fetchRecAdvs,
    importRecAdvsFromTelema,
    createInvoicesInMerit
};
export default connect(mapStateToProps, mapDispatchToProps)(TelemaRecAdvs);
