import ActionType from "../../Models/ActionType";
import * as types from "./types";
import { TelemaOrder } from "../../Models/TelemaOrder";
import { TelemaBulkOrder } from "../../Models/TelemaBulkOrder";
import TelemaRecAdvs from "../../Views/TelemaRecAdvs/TelemaRecAdvs";
import {TelemaRecAdv} from "../../Models/TelemaRecAdv";

export interface TelemaState {
  orders: TelemaOrder[];
  bulkOrders: TelemaBulkOrder[];
  fetchingOrders: boolean;
  fetchingBulkOrders: boolean;
  recAdvs: TelemaRecAdv[],
  fetchingRecAdvs: boolean
}

const initialState: TelemaState = {
  orders: [],
  bulkOrders: [],
  fetchingOrders: false,
  fetchingBulkOrders: false,
  recAdvs: [],
  fetchingRecAdvs: false
};

const Telema = (
  state: TelemaState = initialState,
  action: ActionType
): TelemaState => {
  switch (action.type) {
    case types.FETCH_ORDERS:
      return {
        ...state,
        fetchingOrders: true,
      };
    case types.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        fetchingOrders: false,
        orders: action.payload,
      };
    case types.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        fetchingOrders: false,
      };
    case types.FETCH_BULK_ORDERS:
      return {
        ...state,
        fetchingBulkOrders: true,
      };
    case types.FETCH_BULK_ORDERS_SUCCESS:
      return {
        ...state,
        fetchingBulkOrders: false,
        bulkOrders: action.payload,
      };
    case types.FETCH_BULK_ORDERS_FAILURE:
      return {
        ...state,
        fetchingBulkOrders: false,
      };
    case types.FETCH_REC_ADVS:
      return {
        ...state,
        fetchingRecAdvs: true,
      };
    case types.FETCH_REC_ADVS_SUCCESS:
      return {
        ...state,
        fetchingRecAdvs: false,
        recAdvs: action.payload,
      };
    case types.FETCH_REC_ADVS_FAILURE:
      return {
        ...state,
        fetchingRecAdvs: false,
      };
    default:
      return state;
  }
};

export default Telema;
