import {useState} from "react";
import {Row, Space} from "antd";
import {Product} from "../../Models/Product";
import AddProductModal from "../../Components/ProductsTable/AddProductModal";
import ProductsTable from "../../Components/ProductsTable/ProductsTable";

function ProductsView() {
    let [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined)
    let [revision, setRevision] = useState(1)

    const handleProductSelection = (selectedProduct: Product | undefined) => {
        setSelectedProduct(selectedProduct)
    }

    const nextRevision = () => {
        setRevision(revision + 1)
    }

    const ProductsTableHeader = () => {
        return <Row style={{marginBottom: 10}}>
            <Space>
                <AddProductModal revision={revision} onAdd={() => nextRevision()}/>
            </Space>
        </Row>
    }

    return (
        <>
            <ProductsTableHeader/>
            <ProductsTable onChange={() => {nextRevision()}} revision={revision}/>
        </>
    )
}

export default ProductsView
