import _ from "lodash";
import axios from "axios";

const Defer = () => {
  const result = {};
  result.promise = new Promise((resolve, reject) => {
    result.resolve = resolve;
    result.reject = reject;
  });
  return result;
};

const interceptor = (error) => {
  const { response } = error;

  if (
    (response &&
      (response.status === 429 ||
        response.status === 0 ||
        response.status >= 500)) ||
    error.code === "ECONNABORTED"
  ) {
    const deferred = Defer();
    let retries = _.get(error, "config.retries", 0);
    const retryLimit = 0;

    if (retries >= retryLimit) {
      if (response) {
        return Promise.reject(response);
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        status: 0,
        data: "",
      });
    }

    const defaultDelay = 1000 * retries + Math.round(1000 * Math.random());
    retries += 1;

    setTimeout(async () => {
      try {
        const res = axios.request(Object.assign({}, error.config, { retries }));
        deferred.resolve(res);
      } catch (err) {
        deferred.reject(err);
      }
    }, defaultDelay);

    return deferred.promise;
  }

  return Promise.reject(error);
};

export default interceptor;
