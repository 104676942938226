import React, { useCallback, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Service, ServiceStatus } from "../../Models/Service";
import ProductService from "../../Services/ProductService";
import { Product, ProductDTO } from "../../Models/Product";
import LabelService from "../../Services/LabelService";
import {
  Label,
  LabelDTO,
  ProducerType,
  ProductTempType,
} from "../../Models/Label";
import { LabelType } from "../../Models/LabelType";
import LabelTypeService from "../../Services/LabelTypeService";
import Utils from "../../Utils/Utils";
import { PrinterFilled } from "@ant-design/icons/lib";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface AddLabelModalProps {
  onAdd: () => void;
  revision: number;
}

function AddLabelModal(props: AddLabelModalProps) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [wasAdded, setWasAdded] = useState(false);
  const [showIngredients, setShowIngredients] = useState(false);
  const [form] = Form.useForm();
  const productService = ProductService();
  const labelService = LabelService();
  const labelTypeService = LabelTypeService();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  const handleLabelCreated = () => {
    setWasAdded(true);
    setVisible(false);
    setConfirmLoading(false);
  };

  function handleFinish(values: any) {
    const dto: LabelDTO = {
      barcode: values.barcode.toString(),
      isOrganic: values.isOrganic,
      producerType: values.producerTitle,
      tempType: values.tempType,
      title: values.title,
      weight: values.weight,
      productId: values.productId,
      labelTypeId: values.labelTypeId,
      titleLines: values.titleLines,
      hasStoneWarning: values.hasStoneWarning,
      hasIngredients: values.hasIngredients,
      ingredients: values.ingredients || "",
      flippedHorizontally: values.flippedHorizontally,
    };
    console.log("HANDLE FINISH", values, dto);
    setConfirmLoading(true);
    labelService.post(dto, handleLabelCreated);
  }

  const handleModalDestroy = () => {
    if (wasAdded) {
      props.onAdd();
    }
  };

  function getLabelTyeps(): LabelType[] {
    return Utils.getDataFromService(labelTypeService.get());
  }

  function getProducts(): Product[] {
    return Utils.getDataFromService(productService.get(props.revision));
  }

  const getDefaultValue = (
    arr: any[],
    selectedValue?: any
  ): number | undefined => {
    if (selectedValue !== undefined) {
      return selectedValue.id;
    }
    if (arr.length > 0) {
      return arr[0].id;
    }
    return undefined;
  };

  const onIngredientChange = (e: CheckboxChangeEvent) => {
    setShowIngredients(e.target.checked);
  };

  const ModalForm = () => {
    return (
      <Form
        form={form}
        preserve={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        initialValues={{
          tempType: ProductTempType.FROZEN,
          isOrganic: false,
          producerTitle: ProducerType.PRODUCER,
        }}
        onFinish={handleFinish}
      >
        <Form.Item
          label="Nimetus"
          name="title"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nimetuse ridade arv"
          name="titleLines"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item
          label="Triipkood"
          name="barcode"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="Kaal (g)"
          name="weight"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Mahe" name="isOrganic" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Pööra 180"
          name="flippedHorizontally"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Kivi hoiatus"
          name="hasStoneWarning"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Koostis"
          name="hasIngredients"
          valuePropName="checked"
        >
          <Checkbox onChange={onIngredientChange} />
        </Form.Item>
        {showIngredients && (
          <Form.Item label="Koostise sisu" name="ingredients">
            <Input />
          </Form.Item>
        )}
        <Form.Item label="Temp" name="tempType">
          <Radio.Group>
            <Radio.Button value="FROZEN">-18C</Radio.Button>
            <Radio.Button value="FRESH">+4C</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Tootja nimetus"
          name="producerTitle"
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Radio.Group>
            <Radio.Button value={ProducerType.PRODUCER}>Tootja</Radio.Button>
            <Radio.Button value={ProducerType.PACKAGER}>Pakendaja</Radio.Button>
            <Radio.Button value={ProducerType.IMPORTER}>
              Maaletooja
            </Radio.Button>
            <Radio.Button value={ProducerType.DISTRIBUTOR}>
              Turustaja
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Artikkel"
          name={"productId"}
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Select style={{ width: "100%" }}>
            {getProducts()
              .sort((a, b) =>
                a.title
                  .trim()
                  .toLowerCase()
                  .localeCompare(b.title.trim().toLowerCase())
              )
              .map((product) => (
                <Select.Option key={product.id} value={product.id}>
                  {product.title}: {product.producer.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Etiketi tüüp"
          name={"labelTypeId"}
          rules={[{ required: true, message: "Kohustuslik väli" }]}
        >
          <Select style={{ width: "100%" }}>
            {getLabelTyeps().map((type) => (
              <Select.Option key={type.id} value={type.id}>
                {type.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        Lisa uus etikett
      </Button>
      <Modal
        width={800}
        title="Lisa uus etikett"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleModalDestroy}
        okText={"Lisa"}
        cancelText={"Tühista"}
        confirmLoading={confirmLoading}
        destroyOnClose={true}
      >
        <ModalForm />
      </Modal>
    </>
  );
}

export default AddLabelModal;
