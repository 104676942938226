import ActionType from "./ActionType";

export interface UserCredentials {
  identifier: string;
  password: string;
}

export interface User {
  blocked: boolean;
  confirmed: boolean;
  created_at: string;
  email: string;
  id: number;
  provider: string;
  role: UserRole;
  updated_at: string;
  username: string;
}

export interface UserRole {
  id: number;
  name: string;
  description: string;
  type: UserRoleEnum;
}

export enum UserRoleEnum {
  ADMIN = "authenticated",
  CLIENT = "client",
}

export interface AuthActionType extends ActionType {
  token?: string | null;
  userCredentials?: UserCredentials;
  user?: User;
}
