import RestService from "./RestService";
import Config from "../Config/Config";
import { LabelType, LabelTypeDTO } from "../Models/LabelType";
import { store } from "../App";

const Post = (
  labelTypeDTO: LabelTypeDTO,
  callback: () => void,
  jwt: string | null | undefined
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(mapDTO(labelTypeDTO)),
  };

  fetch(Config.baseURL + "/label-types", requestOptions)
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      callback();
      return response;
    })
    .catch((error) => {
      alert(error);
      console.log(error);
    });
};

function mapDTO(labelDTO: LabelTypeDTO): object {
  return {};
}

function mapResponse(payload: any): LabelType[] {
  return payload.map((p: any) => {
    return {
      id: p.id,
      title: p.title,
      width: p.width,
      height: p.height,
      orientation: p.orientation,
    };
  });
}

const LabelTypeService = () => {
  const state = store.getState();
  const jwt = state.auth.authorizationToken;
  return {
    get: () =>
      RestService.get<LabelType>({
        endpoint: "label-types",
        mapper: mapResponse,
      }),
    post: (labelTypeDTO: LabelTypeDTO, callback: () => void) =>
      Post(labelTypeDTO, callback, jwt),
  };
};

export default LabelTypeService;
