import {
  DocumentFile,
  DocumentFileActionType,
  DocumentFileInfo,
} from "../../Models/DocumentFile";
import * as types from "./types";
import ActionType from "../../Models/ActionType";

export const uploadDocumentFile: (
  file: DocumentFile,
  fileInfo: DocumentFileInfo
) => DocumentFileActionType = (
  file: DocumentFile,
  fileInfo: DocumentFileInfo
) => ({
  type: types.UPLOAD_FILE,
  file,
  fileInfo,
});

export const openFileModal: () => ActionType = () => ({
  type: types.OPEN_MODAL,
});

export const closeFileModal: () => ActionType = () => ({
  type: types.CLOSE_MODAL,
});
