import * as types from "./types";
import ActionType from "../../Models/ActionType";
import {
  TelemaBulkOrderActionType,
  TelemaBulkOrderDTO,
} from "../../Models/TelemaBulkOrder";

export const fetchOrders: () => ActionType = () => ({
  type: types.FETCH_ORDERS,
});

export const importDeliveryNotes: () => ActionType = () => ({
  type: types.IMPORT_DELIVERY_NOTES,
});

export const importOrdersFromTelema: () => ActionType = () => ({
  type: types.IMPORT_ORDERS_FROM_TELEMA,
});

export const importRecAdvsFromTelema: () => ActionType = () => ({
  type: types.IMPORT_REC_ADVS,
});

export const fetchBulkOrders: () => ActionType = () => ({
  type: types.FETCH_BULK_ORDERS,
});

export const fetchRecAdvs: () => ActionType = () => ({
  type: types.FETCH_REC_ADVS,
});

export const createBulkOrder: (
  bulkOrder: TelemaBulkOrderDTO
) => TelemaBulkOrderActionType = (bulkOrder) => ({
  type: types.CREATE_BULK_ORDER,
  bulkOrder,
});

export const downloadBulkOrderXML: (id: number) => ActionType = (id) => ({
  type: types.DOWNLOAD_BULK_ORDER_XML,
  id,
});

export const exportBulkOrderToTelema: (id: number) => ActionType = (id) => ({
  type: types.EXPORT_BULK_ORDER_TO_TELEMA,
  id,
});

export const createInvoicesInMerit: (
    recAdvIds: number[]
) => ActionType = (ids) => ({
  type: types.CREATE_INVOICES_IN_MERIT,
  payload: ids
});
