interface Configuration {
  baseURL: string;
}

const Config: Configuration = {
  baseURL: "https://figuraata-wh.herokuapp.com",
  //baseURL: "http://192.168.1.210:1337",
};

export default Config;
