import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../../Store";
import { authenticateUser } from "../../Store/Auth/actions";
import { User, UserCredentials } from "../../Models/Auth";

interface LoginProps {
  authenticateUser: (user: UserCredentials) => void;
  user?: User | null;
}

function LoginView(props: LoginProps) {
  const [showError, setShowError] = useState(false);
  let location = useLocation();
  let history = useHistory();

  const onFinish = (data: any) => {
    console.log("Success:", data);
    setShowError(false);
    handleSubmit(data);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmit = (data: UserCredentials) => {
    /*auth.signIn(data, (success) => {
            if (!success) {
                setShowError(true)
            } else {
                let {from}: any = location.state || {from: {pathname: "/"}}
                history.replace(from)
            }
        })*/
    const { authenticateUser } = props;
    authenticateUser(data);
  };

  useEffect(() => {
    console.log("EFFECT2", props.user);
    if (props.user) {
      console.log("EFFECT");
      let { from }: any = location.state || { from: { pathname: "/" } };
      history.replace(from);
    }
  });

  return (
    <Row
      align={"middle"}
      justify={"space-around"}
      style={{ minHeight: "100vh" }}
    >
      <Col style={{ minWidth: "20vw" }}>
        {showError && (
          <Row
            justify={"center"}
            style={{ color: "red", marginBottom: "10px" }}
          >
            Vale kasutajanimi või parool
          </Row>
        )}
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Kasutajanimi"
            name="identifier"
            rules={[{ required: true, message: "Palun sisesta kasutajanimi!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Parool"
            name="password"
            rules={[{ required: true, message: "Palun sisesta parool!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Sisene
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state: AppState) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  authenticateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
