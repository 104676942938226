import React, { useState } from "react";
import { Checkbox, Col, Radio, RadioChangeEvent, Row, Space } from "antd";
import AddLabelModal from "../../Components/LabelsTable/AddLabelModal";
import LabelsTable from "../../Components/LabelsTable/LabelsTable";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { RadioGroupOptionType } from "antd/lib/radio";

export enum LabelWeightFilter {
  LESS_THAN_1_KG = 0,
  ONE_KG = 1,
  TWO_HALF_KG = 2,
  MORE_THAN_2_HALF = 3,
}

export enum LabelLanguageFilter {
  EE = 0,
  EN = 1,
  EE_EN = 2,
}

export enum LabelBoxFilter {
  BOX = 0,
  BAG = 1,
}

interface RadioOption {
  label: string;
  value: number;
}

function LabelsView() {
  let [revision, setRevision] = useState(1);

  const nextRevision = () => {
    setRevision(revision + 1);
  };
  const [weightFilter, setWeightFilter] = useState<LabelWeightFilter[]>([]);
  const [languageFilter, setLanguageFilter] = useState<LabelLanguageFilter[]>([
    LabelLanguageFilter.EE,
  ]);
  const [boxFilter, setBoxFilter] = useState<LabelBoxFilter[]>([]);

  const LanguageFilter = () => {
    const options: RadioOption[] = [
      {
        label: "Eesti",
        value: LabelLanguageFilter.EE,
      },
      {
        label: "Inglise",
        value: LabelLanguageFilter.EN,
      },
      {
        label: "EE+EN",
        value: LabelLanguageFilter.EE_EN,
      },
    ];

    const isChecked = (option: RadioOption) => {
      return languageFilter.indexOf(option.value) > -1;
    };

    const handleChange = (optionValue: number, checked: boolean) => {
      const nextLanguageFilter = checked
        ? [optionValue]
        : languageFilter.filter((t) => t !== optionValue);
      setLanguageFilter(nextLanguageFilter);
    };

    return (
      <>
        {options.map((o) => {
          var oIsChecked = isChecked(o);
          return (
            <CheckableTag
              key={o.value}
              checked={oIsChecked}
              onChange={(checked) => handleChange(o.value, checked)}
              style={{
                borderColor: oIsChecked ? "#1890ff" : "#d9d9d9",
                background: oIsChecked ? "#1890ff" : "#ffffff",
                padding: "4px 15px",
                fontSize: "14px",
                margin: 0,
                height: "32px",
              }}
            >
              {o.label}
            </CheckableTag>
          );
        })}
      </>
    );
  };

  const BoxFilter = () => {
    const options: RadioOption[] = [
      {
        label: "Kastisilt",
        value: LabelBoxFilter.BOX,
      },
      {
        label: "Kotisilt",
        value: LabelBoxFilter.BAG,
      },
    ];

    const isChecked = (option: RadioOption) => {
      return boxFilter.indexOf(option.value) > -1;
    };

    const handleChange = (optionValue: number, checked: boolean) => {
      const nextFilter = checked
        ? [optionValue]
        : boxFilter.filter((t) => t !== optionValue);
      setBoxFilter(nextFilter);
    };

    return (
      <>
        {options.map((o) => {
          var oIsChecked = isChecked(o);
          return (
            <CheckableTag
              key={o.value}
              checked={oIsChecked}
              onChange={(checked) => handleChange(o.value, checked)}
              style={{
                borderColor: oIsChecked ? "#1890ff" : "#d9d9d9",
                background: oIsChecked ? "#1890ff" : "#ffffff",
                padding: "4px 15px",
                fontSize: "14px",
                margin: 0,
                height: "32px",
              }}
            >
              {o.label}
            </CheckableTag>
          );
        })}
      </>
    );
  };

  const WeightFilter = () => {
    const options: RadioOption[] = [
      {
        label: "< 1 kg",
        value: LabelWeightFilter.LESS_THAN_1_KG,
      },
      {
        label: "1 kg",
        value: LabelWeightFilter.ONE_KG,
      },
      {
        label: "2,5 kg",
        value: LabelWeightFilter.TWO_HALF_KG,
      },
      {
        label: "> 2,5 kg",
        value: LabelWeightFilter.MORE_THAN_2_HALF,
      },
    ];

    const handleChange = (optionValue: number, checked: boolean) => {
      const nextWeightFilter = checked
        ? [optionValue]
        : weightFilter.filter((t) => t !== optionValue);
      setWeightFilter(nextWeightFilter);
    };

    const isChecked = (option: RadioOption) => {
      return weightFilter.indexOf(option.value) > -1;
    };

    return (
      <>
        {options.map((o) => {
          var oIsChecked = isChecked(o);
          return (
            <CheckableTag
              key={o.value}
              checked={oIsChecked}
              onChange={(checked) => handleChange(o.value, checked)}
              style={{
                borderColor: oIsChecked ? "#1890ff" : "#d9d9d9",
                background: oIsChecked ? "#1890ff" : "#ffffff",
                padding: "4px 15px",
                fontSize: "14px",
                margin: 0,
                height: "32px",
              }}
            >
              {o.label}
            </CheckableTag>
          );
        })}
      </>
    );
  };

  const LabelsTableHeader = () => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col flex="auto">
          <Space size={"large"}>
            <LanguageFilter />
            <WeightFilter />
            <BoxFilter />
          </Space>
        </Col>
        <Col>
          <AddLabelModal revision={revision} onAdd={() => nextRevision()} />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <LabelsTableHeader />
      <LabelsTable
        onChange={() => {
          nextRevision();
        }}
        revision={revision}
        languageFilter={languageFilter}
        weightFilter={weightFilter}
        boxFilter={boxFilter}
      />
    </>
  );
}

export default LabelsView;
